import { useState, useEffect, useCallback } from "react";
import { getDateYYMMDD } from 'utils/_function';
import { useRecoilValue } from 'recoil';
import { outputTypeState } from 'atoms/projectData';

// 解析点の色やサイズを変更する処理
export const useConfigAnalysisPointState = (analysisPointSize, AnalyticeModeType, selectedSelectBoxStartDate, selectedSelectBoxEndDate, setQuantitySearchDate, setSelectedLegendMin, setSelectedLegendMax, legendMin, quantityDates, setSelectedSelectBoxEndDate, setSelectedSelectBoxStartDate) => {
  const [analysisStateChangeFlag, setAnalysisStateChangeFlag] = useState(false); // 解析点の状態変更ボタンがクリックを検知
  const [analysisStateChangeButtonState, setAnalysisStateChangeButtonState] = useState(false); // 解析点の状態変更ボタンのdisbleを定義
  const [nowAnalysisPointState, setNowAnalysisPointState] = useState({ // 現在の解析点の状態を保存
    size: analysisPointSize, // 解析点の大きさ
    type: AnalyticeModeType // 解析点の色
  });
  const [quantityPrevNextDisableFlag, setQuantityPrevNextDisableFlag] = useState({prev: false, next: false}) // 日付選択の次へ前へボタンの非活性状態を管理
  const outputType = useRecoilValue(outputTypeState);

  // 解析点の変更(セレクトボックス)が行われた際の処理
  const handleChangeAnalysisPointState = (value) => {
    // 解析点の状態を変更する処理
    setAnalysisStateChangeFlag(prevState => !prevState);
    // 現在の設定値を保存
    setNowAnalysisPointState(prevState => ({
      ...prevState,
      size: value.size,
      type: value.type
    }));
    //凡例の値を更新
    setQuantitySearchDate({start: selectedSelectBoxStartDate, end: selectedSelectBoxEndDate});
    setSelectedLegendMin(value.legendMin);
    setSelectedLegendMax(value.legendMax);
  }

  // 期間選択の矢印ボタン押下時の処理
  const handleClickSelectDateArrowBtn = useCallback((event) => {
    const selectArrow = event; // prev or next
    const selectStartDateIndex = quantityDates.indexOf(selectedSelectBoxStartDate.replace(/\/0/, '/').replace(/\/0/, '/')); // 選択中の開始日の配列のインデックス番号
    const selectEndDateIndex = quantityDates.indexOf(selectedSelectBoxEndDate.replace(/\/0/, '/').replace(/\/0/, '/')); // 選択中の開始日の配列のインデックス番号
    
    // 前へボタン押下時
    if (selectArrow === 'prev'){
      const prevDate = quantityDates[selectStartDateIndex - 1];//選択中の開始日の次の日を取得
      const afterPrevDate = quantityDates[selectEndDateIndex - 1];//選択中の開始日の次の次の日を取得
      setSelectedSelectBoxStartDate(getDateYYMMDD(prevDate));
      setSelectedSelectBoxEndDate(getDateYYMMDD(afterPrevDate));
      setQuantitySearchDate({start: getDateYYMMDD(prevDate), end: getDateYYMMDD(afterPrevDate)});
    }
    // 次へボタン押下時
    if (selectArrow === 'next'){
      const nextDate = quantityDates[selectStartDateIndex + 1];//選択中の開始日の次の日を取得
      const afterNextDate = quantityDates[selectEndDateIndex + 1];//選択中の開始日の次の次の日を取得
      setSelectedSelectBoxStartDate(getDateYYMMDD(nextDate));
      setSelectedSelectBoxEndDate(getDateYYMMDD(afterNextDate));
      setQuantitySearchDate({start: getDateYYMMDD(nextDate), end: getDateYYMMDD(afterNextDate)});
    }

  },[selectedSelectBoxStartDate, selectedSelectBoxEndDate, quantityDates])

  // 日付選択がされた際に日付の前へ次へボタンの非活性状態の変更処理
  useEffect(() => {
    if(!selectedSelectBoxStartDate) return;
    if(!selectedSelectBoxEndDate) return;
    const startDateIndex = quantityDates.indexOf(selectedSelectBoxStartDate.replace(/\/0/, '/').replace(/\/0/, '/')); // 選択中の開始日の配列のインデックス番号
    const endDateIndex = quantityDates.indexOf(selectedSelectBoxEndDate.replace(/\/0/, '/').replace(/\/0/, '/')); // 選択中の開始日の配列のインデックス番号
    const lastDateIndex = Number(quantityDates.length - 1); // 日付データの最後の日付
    
    // 変動速度選択時は非活性して処理を停止
    if(outputType === 'velocity'){
      setQuantityPrevNextDisableFlag(prevState => ({
        ...prevState,
        prev: true,
        next: true,
      }))
      return;
    }

    // 開始日が日付データの先頭の場合はPrevボタンを非活性にする
    if(startDateIndex === 0){
      setQuantityPrevNextDisableFlag(prevState => ({ ...prevState, prev: true }))
    }else{
      setQuantityPrevNextDisableFlag(prevState => ({ ...prevState, prev: false }))
    }

    // 終了日が日付データの末尾の場合はNextボタンを非活性にする
    if(endDateIndex === lastDateIndex){
      setQuantityPrevNextDisableFlag(prevState => ({ ...prevState, next: true }))
    }else{
      setQuantityPrevNextDisableFlag(prevState => ({ ...prevState, next: false }))
    }

  },[selectedSelectBoxStartDate, selectedSelectBoxEndDate, outputType])
  
  // 反映ボタンのdisabledの状態を変更する処理
  useEffect(() => {
    // 凡例スライダーの値が0または、自動反映スイッチがONの場合は反映ボタンはグレーアウト
    if (legendMin === 0) {
      setAnalysisStateChangeButtonState(true); // 非活性
      return;
    }
    setAnalysisStateChangeButtonState(false); // 活性
  }, [analysisPointSize, AnalyticeModeType, legendMin])

  return {
    analysisStateChangeFlag,
    nowAnalysisPointState,
    handleChangeAnalysisPointState,
    analysisStateChangeButtonState,
    handleClickSelectDateArrowBtn,
    quantityPrevNextDisableFlag
  };
};