import React, { useEffect, useState } from 'react';
import Chart from 'components/map/Chart';
import 'css/Chart.scss';
import 'css/Summary.scss';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CopyRight from 'components/map/CopyRight';
import { SLOPE_FLUCTUATION_RANK, SLOPE_FLUCTUATION_RANK_EN } from 'utils/constants';
import { S3_URL } from "utils/constants";
import { useRecoilValue } from 'recoil';
import { debugFlagState } from 'atoms/projectData';

//解析対象範囲のPDFリンク表示を許可するProjectIdを定義(北陸電力用)
const pdfLinkPermitProjectId = [
  'PJ000075',
  'PJ000091',
];

// ツールチップ内でHTMLを書けるようにする
// https://v4.mui.com/components/tooltips/#tooltip
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#333',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #fff',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  tabButtonArea: {
    zIndex: "9",
    position: "absolute",
    right : "50px",
    bottom: "0",
  },
  menuButton: {
    borderRadius: "0",
  },
  tabPanelArea: {
    width: "100%",
  },
  tabPanelFlex: {
    position: 'absolute',
    top: '-36px',
    right: '50px',
    zIndex: '9',
  },
  tableCellTh: {
    fontSize: '0.7rem',
    '&:first-child': {
      textAlign: 'left',
      width: '100px',
    },
    fontWeight: "bold",
    lineHeight: '1.4',
    padding: '5px',
  },
  noDataCellTh: {
    color: '#fff',
    backgroundColor: '#000',
  },
  Graph: {
    height: "175px",
  },
  chart__date: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-evenly",
  },
  chart__date_back: {
    width: "0",
    height: "0",
    borderTop: "7px solid transparent",
    borderRight: "7px solid black",
    borderBottom: "7px solid transparent",
    position: "relative",
    top: "2px",
    right: "-60px",
  },
  chart__date_forward: {
    width: "0",
    height: "0",
    borderLeft: "7px solid black",
    borderTop: "7px solid transparent",
    borderBottom: "7px solid transparent",
    position: "relative",
    top: "2px",
    left: "-60px",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paper: {
    padding: "10px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  tableCell: {
    padding: '5px',
    fontSize: '0.7rem',
    '&:first-child': {
      textAlign: 'left'
    },
  },
  fontSize12: {
    fontSize: '12px',
  },
  typography: {
    padding: "0 5px",
    textAlign: "left",
    borderBottom: "2px solid",
    fontWeight: 'bold',
    color: "#004ea2",
  },
  aoiTableCell: {
    minWidth: "75px",
    '&:first-child': {
      minWidth: "80px",
    },
  },
  pointTableCell: {
    fontSize: '0.7rem',
    Width: "75px",
    '&:first-child': {
      textAlign: 'left',
      Width: "80px",
    },
    padding: '5px',
  },
  backgroundWhite: {
    background: '#fff',
  },
  rankRecAve: {
    background: '#fff',
  },
  rankRecMax: {
    background: '#fff',
  },
  rankAllMax: {
    background: '#fff',
  },
  rankAllAve: {
    background: '#fff',
  },
  title__summary: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  table__tooltip:{
    borderCollapse: "collapse"
  },
  table__cell:{
    border: "1px solid #000",
    padding: "7px 5px 5px 5px",
  },
  table__rank1:{
    background: '#7e017c',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank2:{
    background: '#fb00ff',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank3:{
    background: '#fa0800',
    color: '#fff',
    fontWeight: "bold",
  },
  table__rank4:{
    background: '#fdfd12',
    fontWeight: "bold",
  },
  table__rank5:{
    background: '#0affff',
    fontWeight: "bold",
  },
}));

export default function Summary(props) {
  const {
    t,
    currentLang,
    projectId,
    accountGroupId,
    aoiRows,
    pointDatasState,
    pointRows,
    handleDrawerOpen,
    handleDrawerClose,
    isSummaryOpen,
    graphMaxConfig,
    graphMinConfig,
    handleChangeInputAdminComment,
    handleClickAdminCommentKeep,
    aoiClickFlag,
    aoiTableDataGeneratFlag,
    setAoiTableDataGeneratFlag,
    summaryTextData,
    clickAoiId,
    resetRegisterAdminComment,
    observationPointData,
    raineyToggleFlag,
    mapStyle,
    analysisVer,
    graphShowHideConfig,
    selectedSelectBoxStartDate,
    selectedSelectBoxEndDate,
    commonMapLayersState
  } = props;

  // Recoilから取得
  const debugFlag = useRecoilValue(debugFlagState);

  const projectColumns = [
    '',
    t("dashboard.twoPeriodsMax"),
    t("dashboard.twoPeriodsAverage"),
    t("dashboard.wholePeriodMax"),
    t("dashboard.wholePeriodAverage"),
  ];
  
  const noDataColumns = [
    '',
    t("map.noAnalysisData"),
    t("map.noAnalysisData"),
    t("map.noAnalysisData"),
    t("map.noAnalysisData"),
  ]

  // グラフ上部に表示する解析ポイントの情報を作成する
  const pointDataRows = {};
  pointRows.forEach((value, index) => {
    switch(index){
      case 1 : // pid
        pointDataRows['pid'] = value;
        break
      case 2 : // lat
        pointDataRows['lat'] = value;
        break
      case 3 : // lng
        pointDataRows['lng'] = value;
        break
      case 4 : // 変動速度
        pointDataRows['velocity'] = value;
        break
      case 5 : // 標高
      pointDataRows['h'] = value;
        break
      case 6 : // 勾配の向き
      pointDataRows['d'] = value;
        break
      case 7 : // 勾配の角度
        pointDataRows['g'] = value;
        break
      case 8 : // θ
        pointDataRows['s'] = value;
        break
      case 9 : // 信頼度
        pointDataRows['c'] = value;
        break
      default :
        break
    }
  });

  const [noDataFlag, setNoDataFlag] = useState(false);// 斜面変動ランクのデータor解析データなしを表示するフラグ

  //AOIがクリックされた場合の処理
  useEffect(() => {
    if(!aoiTableDataGeneratFlag) return;

    setNoDataFlag(false);
    //クリックされたAOIの解析データが無い場合は、フラグを更新
    if(aoiRows.no_data){
      setNoDataFlag(true)
    }
    setAoiTableDataGeneratFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiTableDataGeneratFlag])

  const theme = useTheme();
  const classes = useStyles();

  // 各AOIの詳細PDFを別タブで表示する処理
  const openAoiPdf = () => {
      window.open(`${S3_URL}document/hokuriku/rikuden_${clickAoiId}.pdf`, '_blank');
  }

  return (
    <>
      <div className={classes.tabButtonArea}>
        <Button
          color="primary"
          variant="contained"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          style={{borderRadius: "0"}}
          className={clsx(classes.menuButton, isSummaryOpen && classes.hide)}
        >
          <ExpandLessIcon />
        </Button>
      </div>
      <Drawer
        style={{justifyContent: "flex-end"}}
        variant="persistent"
        anchor="bottom"
        open={isSummaryOpen}
      >
        <div className={classes.tabPanelArea}>
          {/* 著作権表記 */}
          <CopyRight
            mapStyle={mapStyle}
            isSummaryOpen={isSummaryOpen}
            commonMapLayersState={commonMapLayersState}
          ></CopyRight>
          {/* ドロワーメニューの開閉ボタン */}
          <div className={classes.tabPanelFlex}>
            <Button
              style={{borderRadius: "0"}}
              onClick={handleDrawerClose}
              color="primary"
              variant="contained"
            >
              {theme.direction === 'ltr' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
          </div>
          {/* ドロワーメニュー */}
          <div className={classes.backgroundWhite}>
            <div className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                    <div className={classes.title__summary}>
                      <div>{t("map.slopeVariationRankofSelectedArea")}</div>
                        <HtmlTooltip
                          style={{paddingBottom: "10px"}}
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow
                          }}
                          placement="top"
                          arrow
                          title={
                            <>
                             <table className={classes.table__tooltip}>
                              <tr>
                                <th className={classes.table__cell}>{t("map.slopeVariationRank")}</th>
                                <th className={classes.table__cell}>{t("map.variationStatus")}</th>
                                <th className={classes.table__cell}>{t("map.averageMonthlyVariation")}</th>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank1}`}>{t("map.variationSymbolA´´")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA´´")}</td>
                                <td className={classes.table__cell}>300mm/{t("map.moreThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank2}`}>{t("map.variationSymbolA´")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA´")}</td>
                                <td className={classes.table__cell}>30mm/{t("map.moreThanMonth")} 300mm{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank3}`}>{t("map.variationSymbolA")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextA")}</td>
                                <td className={classes.table__cell}>10mm/{t("map.moreThanMonth")} 30mm/{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank4}`}>{t("map.variationSymbolB")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextB")}</td>
                                <td className={classes.table__cell}>2mm/{t("map.moreThanMonth")} 10mm/{t("map.lessThanMonth")}</td>
                              </tr>
                              <tr>
                                <td className={`${classes.table__cell} ${classes.table__rank5}`}>{t("map.variationSymbolC")}</td>
                                <td className={classes.table__cell}>{t("map.variationTextC")}</td>
                                <td className={classes.table__cell}>2mm/{t("map.lessThanMonth")}</td>
                              </tr>
                             </table>
                            </>
                          }
                        >
                          <Button className={classes.helpButton}>
                            <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                          </Button>
                        </HtmlTooltip>
                    </div>
                  </Typography>
                  <div>
                  </div>
                  <div style={{padding: "5px"}}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {projectColumns.map((index, key) => (
                              <TableCell
                                className={classes.tableCellTh}
                                key={key}
                                component="th"
                                align="center">{index}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {noDataFlag ?
                            <TableRow className='nodataRow'>
                              {noDataColumns.map((index, key) => (
                                <TableCell
                                  className={classes.tableCellTh}
                                  key={key}
                                  component="th"
                                  align="center"
                                >
                                  <span className='noData'>{index}</span>
                                </TableCell>
                              ))}
                            </TableRow>
                            :
                            <TableRow>
                              {aoiRows ? Object.keys(aoiRows).map(key =>
                                (key !== 'aoiId') &&
                                <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                  key={key}
                                >
                                  {currentLang === 'ja' ? (
                                    <span className={'rank' + SLOPE_FLUCTUATION_RANK.indexOf(aoiRows[key])}>
                                      {aoiRows[key]}
                                    </span>
                                  ) : currentLang === 'en' ? (
                                    <span className={'rank' + SLOPE_FLUCTUATION_RANK_EN.indexOf(aoiRows[key])}>
                                      {aoiRows[key]}
                                    </span>
                                  ) : null}
                                </TableCell>
                              ) : <TableCell style={{minHeight: '45px'}} className={classes.tableCell}>-</TableCell>}
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
                <div className='summaryUnderArea'>
                  <div className='summaryUnderAreaItem'>
                  { accountGroupId === 'AG000009'
                    ?
                      <Grid item xs={12}
                        style={{paddingLeft: "5px"}}
                      >
                        <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                          選択箇所の管理者コメント
                        </Typography>
                        <div className='summaryCommentArea'>
                          <FormControl className='control'>
                            <div
                              style={{paddingLeft: "3px"}}
                            >
                              <TextField
                                id="adminCommentInput"
                                className='inputArea'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!aoiClickFlag}
                                onChange={ e => {
                                  handleChangeInputAdminComment(e.target.value)
                                }}
                              />
                              <div className='commentContent'>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  onClick={handleClickAdminCommentKeep}
                                  disabled={!aoiClickFlag}
                                >
                                  保存
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={resetRegisterAdminComment}
                                  disabled={!aoiClickFlag}
                                >
                                  リセット
                                </Button>
                              </div>
                            </div>
                          </FormControl>
                        </div>
                      </Grid>
                    :
                      <Grid item xs={12}
                        style={{marginBottom: "15px", paddingLeft: "5px"}}
                      >
                        <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                          {t("map.commentsOnAnalysisResults")}
                        </Typography>
                        <div className='summaryCommentArea'>
                          <div id="adminCommentResult" className='resultContent'>{summaryTextData}</div>
                        </div>
                      </Grid>
                  }
                  {/* 北陸電力用 */}
                  { pdfLinkPermitProjectId.includes(projectId) && aoiClickFlag === true &&
                    <div className='aoiPdfLink'>  
                      <div>
                        <span
                          onClick={openAoiPdf}
                        >
                          参考：本解析対象範囲の二時期差分解析結果
                        </span>
                      </div>
                    </div>
                  }
                  </div>
                  <div className='summaryUnderAreaItem'>
                    <Grid item xs={12} style={{marginLeft: "20px"}}>
                      <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                        {t("map.amountPoints")}
                      </Typography>
                    </Grid>
                    <div>
                      <div>
                        {Object.keys(pointDataRows).length
                        ?
                        <div className='pointDataArea'>
                          <div className='pointData'>
                            <div className='pointDataItem'>・{t("map.rateOfVariation")}（mm/{t("map.year")}）: <span>{pointDataRows.velocity}</span></div>
                            <div className='pointDataItem'>・PID : <span>{pointDataRows.pid}</span></div>
                            <div className='pointDataItem'>・{t("map.Latitude")} : <span>{pointDataRows.lat}</span></div>
                            <div className='pointDataItem'>・{t("map.Longitude")} : <span>{pointDataRows.lng}</span></div>
                          </div>
                          {analysisVer >= 2.0 && (
                            <div className='pointData'>
                              <div className='pointDataItem'>・{t("map.elevation")} : <span>{pointDataRows.h}</span></div>
                              <div className='pointDataItem'>・{t("map.directionOfSlope")} : <span>{pointDataRows.d}</span></div>
                              <div className='pointDataItem'>・{t("map.slopeDirectonSize")} : <span>{pointDataRows.g}</span></div>
                              {pointDataRows.c && debugFlag && <div className='pointDataItem'>・*{t("map.reliability")} : <span>{pointDataRows.c}</span></div>}
                            </div>
                          )}
                        </div>
                        :
                          <div className='pointData'></div>
                        }
                        <div className={classes.Graph}>
                          {pointRows.length > 0 && (
                            <section className={classes.chart__date}>
                              <span style={{height: "14px"}} className={classes.fontSize12}></span>
                            </section>
                          )}
                          {/* グラフエリア */}
                          {pointRows.length > 0 && (
                            <Chart
                              t={t}
                              pointDatasState={pointDatasState}
                              graphMaxConfig={graphMaxConfig}
                              graphMinConfig={graphMinConfig}
                              observationPointData={observationPointData}
                              raineyToggleFlag={raineyToggleFlag}
                              graphShowHideConfig={graphShowHideConfig}
                              selectedSelectBoxStartDate={selectedSelectBoxStartDate}
                              selectedSelectBoxEndDate={selectedSelectBoxEndDate}
                            ></Chart>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
