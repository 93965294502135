import React, { useState, useEffect } from 'react';
import './App.css';
import 'variable.css';
import { Route, Switch, Redirect } from 'react-router';
import ProjectsDashbord from 'pages/ProjectsDashboard';
import MapPage from 'pages/MapPage';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { postRequest } from 'utils/_function';

//コンポーネントの色を変更
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#004ea2',//スカパーブルー
    },
    black: {
      main: '#333'
    }
  }
});

/**
 * auth系はCognitoを使用していて、AmplifyのDocs（下記URL）の Recommended Usage の書き方を参考にしている
 * @see https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#recommended-usage
 */
const AuthStateApp = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [accountGroupId, setAccountGroupId] = useState(false);
  const [accountGroupName, setAccountGroupName] = useState(false);
  const [userName, setUserName] = useState(false);
  const [isShowPurchaseAlertPopup, setIsShowPurchaseAlertPopup] = useState(false); // ダッシュボードへのリダイレクトモーダルの

  /** ユーザー情報からアカウントグループ情報を取得 */
  const fetchUserInfo = async () => {
    if (!user.attributes) return;
    const params = {
      userId: user.attributes.email,
    }
    const response = await postRequest('IM_Lambda', '/im-getuserinfo', params);

    if (response.errorMessage) {
      setAccountGroupId(false);
    }

    if (!response.statusCode) {
      setAccountGroupId(false);
    }

    switch (response.statusCode) {
      case 'N0000': // 正常終了
        setAccountGroupId(response.accountGroupId);
        setAccountGroupName(response.accountGroupName);
        setUserName(response.userName);
        break;
      case 'E0180': // 対象のユーザが存在しない
        setAccountGroupId(false);
        break;
      case 'E0250': // バリデーションチェックによるエラー
        setAccountGroupId(false);
        break;
      case 'E0260': // コード内で指定されたデータが、タグごと入力JSONにない
        setAccountGroupId(false);
        break;
      case 'E0270': // コード内で指定されたデータについて、値が入力JSONにない
        setAccountGroupId(false);
        break;
      default:
        setAccountGroupId(false);
        break;
    }
  }

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path='/map'>
            <MapPage
              user={user}
              userName={userName}
              accountGroupName={accountGroupName}
              accountGroupId={accountGroupId}
              isShowPurchaseAlertPopup={isShowPurchaseAlertPopup}
              setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
            ></MapPage>
          </Route>
          <Route exact path='/'>
            <ProjectsDashbord
              user={user}
              userName={userName}
              accountGroupId={accountGroupId}
              accountGroupName={accountGroupName}
              isShowPurchaseAlertPopup={isShowPurchaseAlertPopup}
              setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
            ></ProjectsDashbord>
          </Route>
          <Redirect to="/"/>
        </Switch>
      </ThemeProvider>
    </div>
  ) : (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn
          slot='sign-in'
          usernameAlias='email'
          hideSignUp={true} />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
}

export default AuthStateApp;