import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import AlertMessage from 'components/common/AlertMessage';
import Loading from "components/common/Loading";
import PopupAoiList from 'components/dashboard/PopupAoiList';
import { getAoiRankName } from 'utils/_function';
import { S3_URL } from "utils/constants";
import { useFetchAoisGeojson } from 'hooks/useFetchAoisGeojson'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { debugFlagState, refProjectIdState, currentLangState, latitudeCenterState, longitudeCenterState, datesState, myProjectIdState, qmaxValueState, pjNameState, zoomState } from 'atoms/projectData';

// 斜面変動ランクの数値を文字列に変換する
const createData = (id, aoiName, lat, lng, rankAllAveText, rankAllMaxText, rankRecAveText, rankRecMaxText, link, noDataFlag) => {

  // AOIのデータ内にno_dataが無い場合
  if(!noDataFlag){
    let rankDataNum = [];
    let rankDataRankAllAve = {name: 'rankAllAve', num: rankAllAveText};
    let rankDatarankAllMax = {name: 'rankAllMax', num: rankAllMaxText};
    let rankDatarankRecAve = {name: 'rankRecAve', num: rankRecAveText};
    let rankDatarankRecMax = {name: 'rankRecMax', num: rankRecMaxText};
    rankDataNum.push(rankDataRankAllAve, rankDatarankAllMax, rankDatarankRecAve, rankDatarankRecMax);
    let rankDataText = getAoiRankName(rankDataNum);
  
    //斜面変動ランクのテキスト情報を格納
    let rankAllAve = rankDataText.rankAllAve;
    let rankAllMax = rankDataText.rankAllMax;
    let rankRecAve = rankDataText.rankRecAve;
    let rankRecMax = rankDataText.rankRecMax;
    return { id, aoiName, lat, lng, rankAllAve, rankAllMax, rankRecAve, rankRecMax, link };
  }

  // AOIのデータ内にno_dataがある場合
  if(noDataFlag){
    let rankDataNum = [];
    let rankDataRankAllAve = {name: 'rankAllAve', num: 'no_data'};
    let rankDatarankAllMax = {name: 'rankAllMax', num: 'no_data'};
    let rankDatarankRecAve = {name: 'rankRecAve', num: 'no_data'};
    let rankDatarankRecMax = {name: 'rankRecMax', num: 'no_data'};
    rankDataNum.push(rankDataRankAllAve, rankDatarankAllMax, rankDatarankRecAve, rankDatarankRecMax);
    let rankDataText = getAoiRankName(rankDataNum);
  
    //斜面変動ランクのテキスト情報を格納
    let rankAllAve = rankDataText.rankAllAve;
    let rankAllMax = rankDataText.rankAllMax;
    let rankRecAve = rankDataText.rankRecAve;
    let rankRecMax = rankDataText.rankRecMax;
    return { id, aoiName, lat, lng, rankAllAve, rankAllMax, rankRecAve, rankRecMax, link };
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            className="dashbordTableHead"
            key={headCell.id}
            align={i === 0 ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  iconButton: {
    padding: '2px',
  },
  link: {
    color: '#004ea2',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  bbNone: {
    borderBottom: 'none',
  },
  positionRelativeTop100: {
    position: "relative",
    top: "100px",
  },
}));

/**
 * Project一覧テーブル、AOI一覧テーブルで使用する共通コンポーネント
 * @param {Array} headCells table head に表示する内容
 * @param {Array} rows  table body に表示するデータ
 * @returns テーブルコンポーネント
 */
export default function DashboardTable({t,currentLang, headCells, rows, alertMesseageProjectInfoList, addLoadingList, accountGroupId, setIsShowPurchaseAlertPopup}) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [openAoiPopup, setOpenAoiPopup] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(false);
  const [selectedAoiDataList, setSelectedAoiDataList] = useState(false);
  const [selectedRefProjectId, setSelectedRefProjectId] = useState(false);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState('');
  const [addLoadingAoiList, setAddLoadingAoiList] = useState(false);
  const [alertMesseageAoiList, setAlertMesseageAoiList] = useState(false);
  const [popupAoiDatas, setPopupAoiDatas] = useState([]);
  const [clickAoiListDates, setClickAoiListDates] = useState([]); // 開くをクリックしたプロジェクトの日付データを格納する

  // Recoil更新関数を定義
  const setRefProjectId = useSetRecoilState(refProjectIdState); // ProjectId
  const setCurrentLang = useSetRecoilState(currentLangState);
  const setLatitudeCenter = useSetRecoilState(latitudeCenterState);
  const setLongitudeCenter = useSetRecoilState(longitudeCenterState);
  const setProjectDates = useSetRecoilState(datesState);
  const setMyProjectId = useSetRecoilState(myProjectIdState);
  const setQmaxValue = useSetRecoilState(qmaxValueState);
  const setPjName = useSetRecoilState(pjNameState);
  const setZoom = useSetRecoilState(zoomState);

  // Recoilに保存
  const handleChangeProjectId = (refProjectId, currentLang, latitudeCenter, longitudeCenter, date, projectId, qmaxValue, pjName) => {
    setRefProjectId(refProjectId);
    setCurrentLang(currentLang);
    setLatitudeCenter(latitudeCenter);
    setLongitudeCenter(longitudeCenter);
    setProjectDates(date);
    setMyProjectId(projectId);
    setQmaxValue(qmaxValue);
    setPjName(pjName);
    setZoom(12);
  }

 // Recoilから取得
  const qmaxDefaultValue = useRecoilValue(qmaxValueState);
  const debugFlag = useRecoilValue(debugFlagState);
  const [qmaxValue, setQmaxdefaultValue] = useState(qmaxDefaultValue); // 凡例の上限値を定義

  // カスタムフック
  const { fetchDashBoardAoiGeojson } = useFetchAoisGeojson();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  /** CSVダウンロードがクリックされたときの処理 */
  const downloadCsv = (extension, id, aoiId) => {
    const link = document.createElement('a');
    const filaName = aoiId ? `analysis_${id}_${aoiId}` : `analysis_${id}`;
    link.href = `${process.env.REACT_APP_S3_CSV_URL}/${id}/${filaName}.${extension}`;
    link.download = `${filaName}.${extension}`;
    link.click();
  }

  /** 解析対象範囲数がクリックされたときの処理 */
  const handleClickOpenAoiPopup = (refProjectId, projectId, projectData) => {
    setSelectedRefProjectId(refProjectId);
    setSelectedPurchaseType(projectData.purchaseType);
    setSelectedProjectId(projectId);
    setClickAoiListDates(projectData.dates);
    setOpenAoiPopup(true);
    setPjName(projectData.name);
  };

  /** 解析対象範囲数のポップアップを閉じたときの処理 */
  const handleCloseOpenAoiPopup = () => {
    setPopupAoiDatas([]);
    setSelectedProjectId(false);
    setOpenAoiPopup(false);
    setSelectedRefProjectId(false);
  };

  //斜面変動ランク一覧がクリックされた時
  useEffect( () => {
    const fetchAoiGeoJson = async () => {
      if (!selectedRefProjectId) return;
      if (!selectedPurchaseType) return;

      setAddLoadingAoiList(true);
      setAlertMesseageAoiList(false);
      const errorMessage = '解析対象範囲が取得できません。';
      let aoisFeatures = []; // geojsonのfeature部分を格納する。

      /**
       * PurchaseTypeがgridの場合は、購入しているAOIのみ斜面変動ランク一覧に表示する
       * PurchaseTypeがfree(grid以外)の場合は、静的ファイルを参照して全AOIを斜面変動ランク一覧に表示する
       */
      if (selectedPurchaseType === 'grid'){
        const result = await fetchDashBoardAoiGeojson(selectedRefProjectId, accountGroupId);
        if(result === 'error'){
          setIsShowPurchaseAlertPopup(true);
          return;
        }
        aoisFeatures = result;
      }else{
        const aoiUrl = `${S3_URL}geojson/${selectedRefProjectId}/aoi.geojson`; // 選択中のProjectのAOIのGeoJSONのURL
        // 選択中のProjectのAOI一覧をGeojsonから取得
        const aoiGeojson = await fetch(aoiUrl).then(response => response.json())
          .then((response) => {
            setAddLoadingAoiList(false); // ローディング非表示
            return response;
          })
          .catch((error) => {
            setAddLoadingAoiList(false); // ローディング非表示
            return false;
          });
        if (aoiGeojson === false) {
          setAlertMesseageAoiList(errorMessage); // 失敗
          return;
        }
        if (!aoiGeojson.features) {
          setAlertMesseageAoiList(errorMessage); // 失敗
          return;
        }
        aoisFeatures = aoiGeojson.features;
      }

      // 斜面変動ランク一覧popupに表示する内容を作成する
      const popupRows = [];
      aoisFeatures.forEach(value => {
        let noDataFlag = false;
        if(value.properties.no_data){
          let noDataFlag = true;
          popupRows.push(createData(value.id, value.properties.aoiName, value.properties.aoiLatCenter, value.properties.aoiLngCenter, value.properties.rankAllAve, value.properties.rankAllMax, value.properties.rankRecAve, value.properties.rankRecMax, t("dashboard.link"), noDataFlag));
        }else{
          popupRows.push(createData(value.id, value.properties.aoiName, value.properties.aoiLatCenter, value.properties.aoiLngCenter, value.properties.rankAllAve, value.properties.rankAllMax, value.properties.rankRecAve, value.properties.rankRecMax, t("dashboard.link"), noDataFlag));
        }
      });
      setPopupAoiDatas(popupRows);
      setSelectedAoiDataList(aoisFeatures);
      setAlertMesseageAoiList(false);
    }
    fetchAoiGeoJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRefProjectId, selectedPurchaseType]);

  return (
    <div className={classes.root && "dashbordTable"}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {addLoadingList && (
                <TableRow>
                  <TableCell
                    className={classes.bbNone}
                    colSpan={13}
                  >
                    <div className={classes.positionRelativeTop100}>
                      <Loading type="list" />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {alertMesseageProjectInfoList &&
                <TableRow>
                  <TableCell
                    colSpan={13}
                    className={classes.bbNone}
                  >
                    <AlertMessage
                      alertSeverity='error'
                      alertMessage={alertMesseageProjectInfoList}
                    />
                  </TableCell>
                </TableRow>
              }
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.projectId}
                      >
                        {
                          Object.keys(row).map(key => {
                            return (
                              ((key !== 'projectId') && (key !== 'refProjectId') && (key !== 'aoiId') && (key !== 'latitudeCenter') && (key !== 'longitudeCenter')) && ((!row.aoiId) && (key !== 'category') && (key !== 'henniMax') && (key !== 'henniAve') && (key !== 'henniCountOverThreshold') && (key !== 'henniPercentageOverThreshold') && (key !== 'dates') && (key !== 'purchaseType')
                              && (key !== 'velocityMax') && (key !== 'velocityAve') && (key !== 'velocityCountOverThreshold') && (key !== 'velocityPercentageOverThreshold')) && (
                                (key === 'name')
                                  ?
                                    <React.Fragment key={key}>
                                      <TableCell
                                        component='th'
                                        scope='row'
                                        align='left'
                                      >
                                        {row.latitudeCenter && row.longitudeCenter
                                          ?
                                            <Link
                                              className={classes.link}
                                              onClick={() => handleChangeProjectId(row.refProjectId, currentLang, row.latitudeCenter, row.longitudeCenter, JSON.parse(row.dates.replace(/'/g, '"')), row.projectId, qmaxValue, row.name)}
                                              to={{
                                                pathname: `/map`,
                                              }}
                                            >
                                              <>{row[key]}{debugFlag && `　*[${row.refProjectId}]`}</>
                                            </Link>
                                          :
                                            <>{row[key]}{debugFlag && `　*[${row.refProjectId}]`}</>
                                        }
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                      >
                                        <Box display='flex'>
                                          <IconButton data-text='project' id={row.projectId} onClick={() => downloadCsv('zip', row.refProjectId)} aria-label='download' className={classes.iconButton}>
                                            <GetAppIcon />
                                          </IconButton>
                                        </Box>
                                      </TableCell>
                                    </React.Fragment>
                                  :
                                    <TableCell
                                      align="right"
                                      key={key}
                                    >
                                      {(key==='aoiCount')
                                        ?
                                          row[key]
                                          ?
                                            row.aoiCount !== '0'
                                            ?
                                              <div
                                                className={classes.link}
                                                onClick={() => handleClickOpenAoiPopup(row.refProjectId, row.projectId, row)}
                                              >
                                                {t("dashboard.open")}
                                              </div>
                                            :
                                              'データなし'
                                          :
                                            '-'
                                        :
                                          row[key] ? row[key] : '-'
                                        }
                                    </TableCell>
                              )
                            )
                          })
                        }
                      </TableRow>
                  );
              })}
              {/* プロジェクト一覧が表示されるまでの領域 */}
              {rows.length === 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={13} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <PopupAoiList
        t={t}
        openAoiPopup={openAoiPopup}
        selectedProjectId={selectedProjectId}
        selectedRefProjectId={selectedRefProjectId}
        selectedAoiDataList={selectedAoiDataList}
        addLoadingAoiList={addLoadingAoiList}
        alertMesseageAoiList={alertMesseageAoiList}
        handleClickOpenAoiPopup={handleClickOpenAoiPopup}
        handleCloseOpenAoiPopup={handleCloseOpenAoiPopup}
        downloadCsv={downloadCsv}
        popupAoiDatas={popupAoiDatas}
        clickAoiListDates={clickAoiListDates}
        qmaxValue={qmaxValue}
        currentLang={currentLang}
      />
    </div>
  );
}
