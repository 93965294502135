import React, { useRef, useEffect, useState, useCallback } from 'react';
import MapboxMap from 'components/map/MapboxMap';
import AnalysisPointFilteringModal from 'components/map/analysisPointFilteringModal';
import UploadRasterNameModal from 'components/map/UploadRasterNameModal';
import Drawer from 'components/map/Drawer';
import { postRequest } from 'utils/_function';
import { SLOPE_FLUCTUATION_RANK, SLOPE_FLUCTUATION_RANK_EN } from 'utils/constants';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { getAoiIdFromAoiName } from 'utils/_function';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import MailIcon from '@material-ui/icons/Mail';
import { useSeachAoiName } from 'hooks/useSeachAoiName'
import { useAoiInsideColor } from 'hooks/useAoiInsideColor'
import { useMemoListZoom } from 'hooks/useMemoListZoom'
import { useCurrentLocationZoom } from 'hooks/useCurrentLocationZoom'
import { useAdminComment } from 'hooks/useAdminComment'
import { useFetchAoiAdminComment } from 'hooks/useFetchAoiAdminComment'
import { useAddConfigUserLatLng } from 'hooks/useAddConfigUserLatLng'
import { useFetchZenrinAuthUrl } from 'hooks/useFetchZenrinAuthUrl'
import { useFetchZnetAuthUrl } from 'hooks/useFetchZnetAuthUrl'
import { useCommonMapLayer } from 'hooks/useCommonMapLayer'
import { useChangeAnalyticeMode } from 'hooks/useChangeAnalyticeMode'
import { useUpdateMemo } from 'hooks/useUpdateMemo'
import { useFetchWeatherData } from 'hooks/useFetchWeatherData'
import { useCommonSnackbar } from 'hooks/useCommonSnackbar'
import { useAoiFiltering } from 'hooks/useAoiFiltering'
import { useGetPurchaseInfo } from 'hooks/useGetPurchaseInfo'
import { useFetchAoisGeojson } from 'hooks/useFetchAoisGeojson'
import { useAoiFilterfingForGrid } from 'hooks/useAoiFilterfingForGrid'
import { useConfigAnalysisPointSize } from 'hooks/useConfigAnalysisPointSize'
import { useConfigAnalysisPointState } from 'hooks/useConfigAnalysisPointState'
import { useAnalysisPointFiltering } from 'hooks/useAnalysisPointFiltering'
import { useAddRasterImgData } from 'hooks/useAddRasterImgData'
import { S3_URL } from "utils/constants";
import { round } from '@turf/turf';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { debugFlagState, refProjectIdState, currentLangState, latitudeCenterState, longitudeCenterState, datesState, myProjectIdState, qmaxValueState, pjNameState, zoomState, outputTypeState } from 'atoms/projectData';

const useStyles = makeStyles(() => ({
  iconButton: {
    background: '#fff',
    padding: '4px',
    position: 'absolute',
    right: '9px',
    top: '10px',
    zIndex: '9999',
  },
  accoutIcon: {
    fontSize: '1.2rem',
    marginRight: '10px'
  },
  versionTxt: {
    fontSize: '0.6rem',
    color: '#676767',
    padding: '0px 16px 0px 16px',
    textAlign: 'right',
  },
}));

export default function Map(props) {

  // Recoilから取得
  const debugFlag = useRecoilValue(debugFlagState);
  const projectId = useRecoilValue(refProjectIdState);
  const currentLang = useRecoilValue(currentLangState);
  const latitudeCenter = useRecoilValue(latitudeCenterState);
  const longitudeCenter = useRecoilValue(longitudeCenterState);
  const projectDates = useRecoilValue(datesState);
  const myProjectId = useRecoilValue(myProjectIdState);
  const qmaxValue = useRecoilValue(qmaxValueState);
  const pjName = useRecoilValue(pjNameState);
  const zoom = useRecoilValue(zoomState);
  const outputType = useRecoilValue(outputTypeState);

  // Recoilの更新関数を定義
  const setQmaxValue = useSetRecoilState(qmaxValueState);
  const setOutputTypeState = useSetRecoilState(outputTypeState);

  const {
    user,
    userName,
    accountGroupName,
    accountGroupId,
    isShowPurchaseAlertPopup,
    setIsShowPurchaseAlertPopup,
  } = props;
  // AOIの内側領域の色を変更する機能のロジック
  const { selectInsideColor, aoiInsideColorBtn, prevInsideColorId, handleChangeSelectInsideColor, handleClickAoiInsideColorChange, setprevInsideColorId, } = useAoiInsideColor();
  const classes = useStyles();
  const [rasterInfoList, setRasterInfoList] = useState();
  const [mapLoading, setMapLoading] = useState(false); // マップ画面のローディングの表示を管理
  // マップ画面の設定値情報を取得するロジック
  // const { userConfigData, getUserCofigExe, setGetUserCofigExe } = useGetUserConfig(projectId, user.attributes.email);
  // AOIが購入済みかどうか確認するロジック
  const { aoiPurchaseData, projectAoiType, aoiPurchaseExeFlag, setAoiPurchaseExeFlag, handleChangePurchaseFill, handleChangePurchaseOutLine, } = useGetPurchaseInfo(projectId, accountGroupId, setMapLoading, setIsShowPurchaseAlertPopup);
  // Gridフィルターモーダルのロジック
  const { 
    isGridFilterModal,
    adjoinNum,
    gridAoiShowType,
    reFetchAoiData,
    handleClickGridFilterModalBtn,
    handleChangeGridFilterAdjon,
    handleChangeGridFilterShowType,
    handleClickGridFilterExeBtn,
    handleClickGridFilterReset,
  } = useAoiFilterfingForGrid()

  // 言語の設定
  useEffect(() => {
    if (currentLang === 'en') i18n.changeLanguage('en');
    if (currentLang === 'ja') i18n.changeLanguage('ja');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { t } = useTranslation();

  // カスタムフック
  const { isSnackbar, snackbarColor, snackbarMessage, handleCloseSnackbar, isShowSnakbar } = useCommonSnackbar();
  const { memoItems, memoStatusCode, memoErrorMessage, memoGetFirstFlag, memoZoomItem, setMemoItems, setMemoStatusCode, setMemoGetFirstFlag, handleClickMemoZoom, } = useMemoListZoom(user, myProjectId);
  const { nowLocationLatLng, nowLocationLoadingFlag, handleClickNowLocation } = useCurrentLocationZoom();
  const { adminComment, getAdminCommentAlertFlag, summaryTextData, setSummaryTextData, setGetAdminCommentAlertFlag, fetchAoiAdminComment, } = useFetchAoiAdminComment(projectId);
  const { regsiterResetFlag, mapNowLatLngData, mapNowLatLngFlag, setMapNowLatLngFlag, setMapNowLatLngData, handleClickConfigLatLng, handleClickAddResetUserLatLng, mapRegsiterLatLngData, regsiterMessageFlag, setRegsiterMessageFlag, regsiterMessage, isSuccess } = useAddConfigUserLatLng(projectId, user.attributes.email);
  const { commonMapLayerOpacity, GeologyDataGetMode, setgeologyDataGetMode, handleChangeCommonMapLayerOpacity, handleChangeGeologyDataMode, commonMapLayersState, handleClickCommonMapLayers, nowCheckCommonLayer } = useCommonMapLayer();
  const { memoUpdateId, isUpdateMemoSuccess, memoUpdateFormStatus, memoUpdateTitle, memoUpdateText, isMemoFormUpdateValid, isMemoTitleUpdateValid, alertUpdateMessageMemoForm, upDateLoadingMemo, isUpdateAlertFormOpen, updateMemo, handleUpdatePopupCancelButton, handleChangeUpdateMemoFormValidate, handleChangeUpdateMemoTitleValidate, openUpdateMemoPopup, } = useUpdateMemo(user, myProjectId, memoItems);
  const { weatherLoadingName, weatherLoadingFlag, raineyToggleFlag, observationPointData, showWeaterItem, weaterObservationData, weatherPopupStatus, weaterToggleFlag, weaterObservationDataFetchFlag, observationPopupContent, weatherToggleChecked, getDateFromGeojson, handleClickIsWeatherPopup, handleClickCloseWeatherPopup, fetchWeatherData, raineyToggleChecked, setWeaterMapCenterLatlng } = useFetchWeatherData(isShowSnakbar);
  const { formatGeojson, isFetchfirstAoigeojson, reFetchAoiLoading, analysisVer, setReFetchAoiLoading, setIsFetchfirstAoigeojson } = useFetchAoisGeojson(projectId, accountGroupId, latitudeCenter, longitudeCenter, projectDates, projectAoiType, reFetchAoiData, setIsShowPurchaseAlertPopup, setMapLoading);
  const { analysisPointSize, handleChangeAnalysisPointSize } = useConfigAnalysisPointSize(debugFlag);
  const { analysisFilteringModalIsOpen, handleChangeAnalysisFilteringModalOpen, handleChangeAnalysisFilteringModalClose, filteringSubjects, filtergingConditions, analysisFilteringArrays, handleClickAddfilteringRecord, addFilteringFlag, handleClickRemovefilteringRecord, handleChangeFilteringTextSelectInput, handleClickAnalysisPonitFilteringFlag, handleClickAnalysisPonitFilteringResetFlag, analysisFilteringFlag, analysisFilteringResetFlag, setAnalysisFilteringFlag, setAnalysisFilteringResetFlag, analysisFilteringIsActive, setAnalysisFilteringIsActive, } = useAnalysisPointFiltering(analysisVer, debugFlag); // 解析点フィルタリング
  const { fileInputRef, rasterNameInputModalIsOpen, handleChangeSelectRasterFile, handleClickRasterNameInputModalClose, handleClickRasterNameInput, handleClickRasterDataPutS3, handleClickUploadBtn } = useAddRasterImgData(setMapLoading, projectId, accountGroupId);
  
  // 現在のズームレベルを格納する処理
  const [nowZoomLevel, setNowZoomLevel] = useState(zoom)
  const [nowZoomLevelGetFlag, setNowZoomLevelGetFlag] = useState(false)
  const handleClickNowZoomLevel = useCallback(() => {
    setNowZoomLevelGetFlag(prevState => !prevState)
  },[])

  //ドロワーメニューの開閉時にmapboxロゴを見える位置に移動する
  const mapboxLogoMove = (value) => {
    let mapboxLogo =  document.getElementsByClassName('mapboxgl-ctrl-bottom-left')[0];
    // Drawer開くとき
    if(value){
      mapboxLogo.classList.remove('close');
      mapboxLogo.classList.add('open');
    }
    // Drawer閉じるとき
    if(!value){
      mapboxLogo.classList.add('close');
      mapboxLogo.classList.remove('open');
    }
  }

  // Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(true); // ドロワーメニューが表示されているか
  const [configUserLatLngDatas, setConfigUserLatLngDatas] = useState({}); // 初期表示位置データをDrawer.jsに渡す用
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    mapboxLogoMove(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    mapboxLogoMove(false);
  };

  // 解析出力設定 radio
  // const [outputType, setOutputType] = useState('velocity');
  const handleChangeOutputType = (event) => {
    // スライダーの最大値の変更
    if (event.target.value === 'velocity') setQmaxValue(50);
    if (event.target.value === 'quantity') setQmaxValue(250);
    if (event.target.value === 'quantitySpeed') setQmaxValue(250);

    // 現在の凡例設定を保存
    setOutputTypeState(event.target.value);
  };

  // 解析対象範囲の表示管理
  const [aoisState, setAoisState] = useState({}); // AOIが選択中かどうか管理 {'AOIの名前', true or false}
  const aoisStateRef = useRef();
  aoisStateRef.current = aoisState; // selectedAoiIdsの最新の状態を参照する
  const [isAllAoisChecked, setIsAllAoisChecked] = useState(false); // AOIのALLがチェックされているか

  // 解析モードを変更するカスタムフック
  const { AnalyticeModeType, isSlider, AnalyticeIsShowAoi, usageGuideunit, ModeTypes, handleChangeAnalyticeMode, setAnalyticeIsShowAoi, getDarkColor, isAbsMode, handleChangeAbsMode, opacityTypes, handleChangeAnalysisOpacity, AnalyticeModeOpacityType } = useChangeAnalyticeMode(setOutputTypeState, t, analysisVer, debugFlag);

  // 地図表示 radio
  const defaultSelectMap = debugFlag ? 'mapbox://styles/mapbox/dark-v10' : 'gsi_map';
  const [mapStyle, setMapStyle] = useState(defaultSelectMap); // ドロワーメニューの地図表示で選択されている値
  const defaultMapStyle = !debugFlag ? {
    'version': 8,
    'sources': {
      'gsi_map': {
        'type': 'raster',
        'tiles': [
          'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png'
        ],
        'tileSize': 256,
        'attribution': "地理院タイル"
      }
    },
    'layers': [
      {
        'id': '地理院標準地図',
        'type': 'raster',
        'source': 'gsi_map',
        'paint': {
          'raster-opacity': 0.8
        }
      },
    ]
  } : 'mapbox://styles/mapbox/dark-v10';
  const [mapInstanceStyle, setMapInstanceStyle] = useState(defaultMapStyle); // Mapboxのmapインスタンスのstyleに設定する値
  const { setZenrinAuthUrlFetchFlag, zenrinMapStyle, zenrinMapLoading, setZenrinMapLoading, zenrinErrorMessage, zenrinFetchErrorFlag, setZenrinFetchErrorFlag } = useFetchZenrinAuthUrl();
  const { znetFetchErrorFlag, znetMapStyle, znetErrorMessage, znetMapLoading, setZnetAuthUrlFetchFlag, setZnetFetchErrorFlag, setZnetMapLoading } = useFetchZnetAuthUrl();
  const handleChangeMapStyle = (event) => {
    let selectedMapStyle = event.target.value;

    // 表示している解析点を一旦非表示にする処理
    setAoisState(prevState => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      return newState;
    });

    //ZENRINが選択された場合の処理
    if (selectedMapStyle === 'zenrin'){
      setZenrinMapLoading(true);
      setMapStyle(event.target.value);
      setZenrinAuthUrlFetchFlag(true);
      return;
    }

    //ZNETが選択された場合の処理
    if (selectedMapStyle === 'znet'){
      setZenrinMapLoading(true);
      setMapStyle(event.target.value);
      setZnetAuthUrlFetchFlag(true);
      return;
    }

    // 地理院航空画像が選択されたときは専用のラスタ画像を使用
    // @see https://docs.mapbox.com/mapbox-gl-js/example/map-tiles/
    if (selectedMapStyle === 'gsi_img') {
      selectedMapStyle = {
        'version': 8,
        'sources': {
          'gsi_image': {
            'type': 'raster',
            'tiles': [
              'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg'
            ],
            'tileSize': 256,
            'attribution': "地理院タイル"
          }
        },
        'layers': [
          {
            'id': '地理院航空画像',
            'type': 'raster',
            'source': 'gsi_image',
            'paint': {
              'raster-opacity': 0.8
            }
          },
        ]
      }
    }
    if (selectedMapStyle === 'gsi_map') {
      selectedMapStyle = {
        'version': 8,
        'sources': {
          'gsi_map': {
            'type': 'raster',
            'tiles': [
              'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png'
            ],
            'tileSize': 256,
            'attribution': "地理院タイル"
          }
        },
        'layers': [
          {
            'id': '地理院標準地図',
            'type': 'raster',
            'source': 'gsi_map',
            'paint': {
              'raster-opacity': 0.8
            }
          },
        ]
      }
    }

    setMapStyle(event.target.value);
    setMapInstanceStyle(selectedMapStyle);

    // 観測地点データアイコンが表示中は同機能をOFFにする
    const weatherOff = { target:{ checked: false } }
    weatherToggleChecked(weatherOff); // 気象toggleスイッチをOFF
  };

  // 背景地図をZERINに変更する処理
  useEffect(() => {
    if(Object.keys(zenrinMapStyle).length === 0) return;
    setMapInstanceStyle(zenrinMapStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinMapStyle])

  // 背景地図をZNETに変更する処理
  useEffect(() => {
    if(Object.keys(znetMapStyle).length === 0) return;
    setMapInstanceStyle(znetMapStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetMapStyle])

  // ZERIN地図選択時にエラー発生時の処理
  useEffect(() => {
    if(!zenrinFetchErrorFlag) return;

    //地理院標準地図に強制移動する
    setMapStyle('gsi_map');
    setMapInstanceStyle(defaultMapStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinFetchErrorFlag])

  // ZNET地図選択時にエラー発生時の処理
  useEffect(() => {
    if(!znetFetchErrorFlag) return;

    //地理院標準地図に強制移動する
    setMapStyle('gsi_map');
    setMapInstanceStyle(defaultMapStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetFetchErrorFlag])

  // ラスター checkbox
  const [mapLayerState, setMapLayerState] = useState({});
  const handleChangeMapLayerCheckbox = (event) => {
    setMapLayerState({ ...mapLayerState, [event.target.value]: event.target.checked });
  };

  // オブジェクト checkbox
  const [objectLayerState, setObjectLayerState] = useState({
    [t("map.memo")]: false,
  });
  const handleChangeObjectLayerCheckbox = (event) => {
    setObjectLayerState({ ...objectLayerState, [event.target.value]: event.target.checked });
  };

  const [mapLayersUrls, setMapLayersUrl] = useState({});

  //絞り込み検索機能
  const [aoisListData, setAoisListData] = useState([]); // ドロワーメニューに渡すAOIデータ
  const [selectRankPeriod, setSelectRankPeriod] = useState(''); // 解析対象範囲の絞り込み検索の「基準する期間」のデータ
  const [selectRankLevel, setSelectRankLevel] = useState(''); // 解析対象範囲の絞り込み検索の「変動ランク」のデータ
  const [selectRankPeriodErrorFlag, setSelectRankPeriodErrorFlag] = useState(false); // 絞り込み検索の「基準する期間」のバリデーションフラグ
  const [selectRankLevelErrorFlag, setSelectRankLevelErrorFlag] = useState(false); // 絞り込み検索の「変動ランク」のバリデーションフラグ
  const [sortAoiDataIdList, setSortAoiDataIdList] = useState([]); // 絞り込み検索で条件が合致したaoiのidのリスト
  const [sortResetAoiDataIdList, setSortResetAoiDataIdList] = useState(false); // 絞り込み検索のリセットボタンがクリックされたかどうかのフラグ
  const [prevAoiSearchIdList, setPrevAoiSearchIdList] = useState([]); // 絞り込み検索後に検索条件に一致したaoiのidの格納する
  const [prevAoiDeleteIdList, setPrevAoiDeleteIdList]  = useState([]); // 絞り込み検索後に検索条件に一致しなかったaoiのidの格納する
 
  /** 解析対象範囲の絞り込み検索の「基準する期間」の変更処理 */
  const handleChangeSelectRankPeriod = (event) => {
    setSelectRankPeriod(event.target.value);
  }

  /** 解析対象範囲の絞り込み検索の「変動ランク」の変更処理 */
  const handleChangeSelectRankLevel = (event) => {
    setSelectRankLevel(event.target.value);
  }
 
  /** 絞り込み検索に一致したaoiのidを取得する処理 */
  const handleChangeAoiListSort = () => {
    handleClickSortAoiNameReset();
    let validatePeriodFlag = false;
    let validateRankFlag = false;
    
    //選択された入力値(基準する期間)のバリデーション
    if(selectRankPeriod === '' || selectRankPeriod == 'selectNone'){
      setSelectRankPeriodErrorFlag(true);
    }else{
      setSelectRankPeriodErrorFlag(false);
      validatePeriodFlag = true;
    }

    //選択された入力値(変動ランク)のバリデーション
    if(selectRankLevel == '' || selectRankLevel == 'selectNone'){
      setSelectRankLevelErrorFlag(true);
    }else{
      setSelectRankLevelErrorFlag(false);
      validateRankFlag = true;
    }

    //絞り込み検索条件が正しい場合は以下を実行
    if(validatePeriodFlag && validateRankFlag){

      // 絞り込んだAOI一覧データを作成
      let sortDatas = [];
      aoisData.forEach(value => {
        //各データ名を定義
        let sortData = [];
        let aoiNameValue = {};
        let aoiName = {};
        let rankAllAve = {};
        let rankAllMax = {};
        let rankRecAve = {};
        let rankRecMax = {};

        //絞り込み検索条件に合致するAOIのみの配列を作成
        if(value.properties[selectRankPeriod] == selectRankLevel){
          aoiName[value.properties.aoiName] = false;
          aoiNameValue['aoiNameValue'] = value.properties.aoiName;
          rankAllAve['rankAllAve'] = value.properties.rankAllAve;
          rankAllMax["rankAllMax"] = value.properties.rankAllMax;
          rankRecAve["rankRecAve"] = value.properties.rankRecAve;
          rankRecMax["rankRecMax"] = value.properties.rankRecMax;
          sortData.push(aoiName, aoiNameValue, rankAllAve, rankAllMax, rankRecAve, rankRecMax);
          sortDatas.push(sortData);
        }
      })
      setAoisListData(sortDatas);

      // 絞り込んだAOIのidのリスト作成
      let sortFeaturesDatas = [];
      aoisGeojson.features.forEach(value => {
        if(value.properties[selectRankPeriod] == selectRankLevel){
          sortFeaturesDatas.push(value.id);
        }
      })
      setSortAoiDataIdList(sortFeaturesDatas);
      setSortResetAoiDataIdList(false);
    }
  }
 
  /** 絞り込み検索をリセットする処理 */
  const handleChangeAoiListReset = () => {
    setSelectRankPeriod('selectNone');
    setSelectRankLevel('selectNone');
    setSelectRankPeriodErrorFlag(false);
    setSelectRankLevelErrorFlag(false);

    let sortDatas = [];
    aoisData.forEach(value => {
      let sortData = [];
      let aoiNameValue = {};
      let aoiName = {};
      let rankAllAve = {};
      let rankAllMax = {};
      let rankRecAve = {};
      let rankRecMax = {};
      aoiName[value.properties.aoiName] = false;
      aoiNameValue['aoiNameValue'] = value.properties.aoiName;
      rankAllAve['rankAllAve'] = value.properties.rankAllAve;
      rankAllMax["rankAllMax"] = value.properties.rankAllMax;
      rankRecAve["rankRecAve"] = value.properties.rankRecAve;
      rankRecMax["rankRecMax"] = value.properties.rankRecMax;
      sortData.push(aoiName, aoiNameValue, rankAllAve, rankAllMax, rankRecAve, rankRecMax);
      sortDatas.push(sortData);
    })
    setAoisListData(sortDatas);
    setSortResetAoiDataIdList(true);
  }

  /** AOIのチェックボックスを変化させたときのAOIの状態の変化 */
  const handleChangeAoisStateWithCheckbox = (event) => {
    const changedAoisState = { ...aoisState, [event.target.value]: event.target.checked }
    setAoisState(changedAoisState);
    const checkBoxCount = Object.values(changedAoisState).length;
    const checkedCount = Object.values(changedAoisState).filter(value => value).length;
    if (checkBoxCount !== checkedCount) setIsAllAoisChecked(false);
    if (checkBoxCount === checkedCount) setIsAllAoisChecked(true);

    // 解析ポイントが表示されているAOIのIDが格納されている配列にIDを追加及び削除する処理
    let aoiId = getAoiIdFromAoiName(event.target.value, aoisData);
    if (event.target.checked) setAnalyticeIsShowAoi((oldValue => [...oldValue, aoiId])); // 追加
    if (!event.target.checked) setAnalyticeIsShowAoi(AnalyticeIsShowAoi.filter((AnalyticeIsShowAoi) => (AnalyticeIsShowAoi !== aoiId))); // 削除
  }

  /**
   * 解析対象範囲のすべてリセットボタン押下時の処理
   * @param {boolean} value 全ての対象範囲をオンorオフ
  */
  const handleChangeAoisStateOnOffBtn = (value) => {
    const aoiCheckBoxesState = Object.assign({}, ...Object.keys(aoisState).map(aoiName => {
      return { [aoiName]: value }
    }));
    setAoisState(aoiCheckBoxesState);
    setIsAllAoisChecked(value);

    // 全AOI名の配列を作成
    const aoiNameList = Object.keys(aoisState).map( aoiName => {
      return aoiName;
    });

    // 全AOI名の配列をIDに変換
    let aoiIdList = [];
    aoiNameList.forEach(aoiName => {
      let aoiId = getAoiIdFromAoiName(aoiName, aoisData);
      aoiIdList.push(aoiId);
    });

    // 解析ポイントが表示されているAOIのIDが格納されている配列にIDを追加及び削除する処理
    if (value) setAnalyticeIsShowAoi(aoiIdList); // 全ての追加
    if (!value) setAnalyticeIsShowAoi([]); // 全ての削除
    
  }

  /** Map中のAOIをクリックしたときのAOIの状態を変化 */
  const handleChangeAoisStateWithAoiClick = (aoiName, status) => {
    // NOTE useState(aoisState)だと最新のAOIの状態を取得できなかったので、useRefを使用した
    const changedAoisState = { ...aoisStateRef.current, [aoiName]: status };
    setAoisState(changedAoisState);
    const checkBoxCount = Object.values(changedAoisState).length;
    const checkedCount = Object.values(changedAoisState).filter(value => value).length;
    if (checkBoxCount !== checkedCount) setIsAllAoisChecked(false);
    if (checkBoxCount === checkedCount) setIsAllAoisChecked(true);
  }

  /** Map Layer を取得（APIができるまでダミーデータ） */
  const fetchMapLayers = async () => {
    const params = {
      projectId: projectId,
    }
    const response = await postRequest('IM_Lambda', '/im_getrasterinfolist', params);

    if (response.errorMessage) {
      return;
    }
    if (!response.statusCode) {
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setRasterInfoList(response.rasterInfoList);
        break;
      default:
        break;
    }
  }

  const [aoisTableData, setAoisTableData] = useState([]);



  /** IM_GetAoiDataList から取得したデータをAOIのウィンドウ用に整形 */
  const createAoiWindowTableData = (aoisInfo, date) => {
    if (!aoisInfo) return;
    return aoisInfo.map(info => {
      // no_data = AOIが解析データなしのフラグ
      if (info.properties.no_data){
        //no_dataがtrueの場合はフラグを持たせてreturnする
        if(currentLang === 'ja'){
          return {
            aoiId: info.id,
            name: info.properties.aoiName,
            rankRecMax: SLOPE_FLUCTUATION_RANK[info.properties.rankRecMax],
            rankRecAve: SLOPE_FLUCTUATION_RANK[info.properties.rankRecAve],
            rankAllMax: SLOPE_FLUCTUATION_RANK[info.properties.rankAllMax],
            rankAllAve: SLOPE_FLUCTUATION_RANK[info.properties.rankAllAve],
            no_data: info.properties['no_data'],
          }
        }else if(currentLang === 'en'){
          return {
            aoiId: info.id,
            name: info.properties.aoiName,
            rankRecMax: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankRecMax],
            rankRecAve: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankRecAve],
            rankAllMax: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankAllMax],
            rankAllAve: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankAllAve],
            no_data: info.properties['no_data'],
          }
        }
      }else{
        //no_dataがfalseの場合は、フラグを持たせずreturnする
        if(currentLang === 'ja'){
          return {
            aoiId: info.id,
            name: info.properties.aoiName,
            rankRecMax: SLOPE_FLUCTUATION_RANK[info.properties.rankRecMax],
            rankRecAve: SLOPE_FLUCTUATION_RANK[info.properties.rankRecAve],
            rankAllMax: SLOPE_FLUCTUATION_RANK[info.properties.rankAllMax],
            rankAllAve: SLOPE_FLUCTUATION_RANK[info.properties.rankAllAve],
          }
        }else if(currentLang === 'en'){
          return {
            aoiId: info.id,
            name: info.properties.aoiName,
            rankRecMax: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankRecMax],
            rankRecAve: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankRecAve],
            rankAllMax: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankAllMax],
            rankAllAve: SLOPE_FLUCTUATION_RANK_EN[info.properties.rankAllAve],
          }
        }
      }
    });
  }

  // AOIのチェック欄のZoomボタン
  const [zoomAoiId, setZoomAoiId] = useState(null); // ZoomするAOIのID
  const handleClickAoiZoomButton = (aoiName) => {
    const aoiId = getAoiIdFromAoiName(aoiName, aoisData);
    setZoomAoiId(aoiId);
  }

  const [aoisData, setAoisData] = useState(); // プロジェクトのAOI一覧データ
  //解析対象範囲名検索
  const { handleChangeInputSeachAoiName, handleClickSeachAoiName, handleClickSortAoiNameReset } = useSeachAoiName(handleChangeAoiListReset, aoisData, setAoisListData, setSortAoiDataIdList, setSortResetAoiDataIdList);
  //管理者コメント機能
  const { regsiterFetchFlag, setRegsiterFetchFlag, adminCommentResultMessage, isSuccessAdminComment, aoiClickFlag, registerAdminCommentLoadingFlag, setAoiClickFlag, setAdminCommentAoiId, handleChangeInputAdminComment, handleClickAdminCommentKeep, resetRegisterAdminComment } = useAdminComment(projectId);

  /** Map画面を表示時にドロワーメニューを準備 */
  const prepareDrawerMenu = (aois, rasterInfoList) => {
    // ラスターのチェックボックス
    let mapLayersCheckBoxes = {};
    let mapLayersUrls = {};
    rasterInfoList && rasterInfoList.forEach(mapLayerInfo => {
      mapLayersCheckBoxes[mapLayerInfo.rasterName] = false;

      mapLayersUrls[mapLayerInfo.rasterName] = `${S3_URL}map_layer/raster/${mapLayerInfo.rasterId}/{z}/{x}/{y}.png`;
    });
    setMapLayerState(mapLayersCheckBoxes);
    setMapLayersUrl(mapLayersUrls);

    // AOIのチェックボックス
    let aoisCheckBoxes = {};
    aois && aois.forEach(aoi => {
      aoisCheckBoxes[aoi.properties.aoiName] = false;
    })
    setAoisState(aoisCheckBoxes);

    // 変動ランク入りのAOIのチェックボックスデータ
    let aoisList = [];
    aois && aois.forEach(aoi => {
      let aoiDatas = [];
      let aoiNameValue = {};
      let aoiName = {};
      let rankAllAve = {};
      let rankAllMax = {};
      let rankRecAve = {};
      let rankRecMax = {};

      aoiName[aoi.properties.aoiName] = false;
      aoiNameValue['aoiNameValue'] = aoi.properties.aoiName;
      rankAllAve['rankAllAve'] = aoi.properties.rankAllAve;
      rankAllMax["rankAllMax"] = aoi.properties.rankAllMax;
      rankRecAve["rankRecAve"] = aoi.properties.rankRecAve;
      rankRecMax["rankRecMax"] = aoi.properties.rankRecMax;
      aoiDatas.push(aoiName, aoiNameValue, rankAllAve, rankAllMax, rankRecAve, rankRecMax);
      aoisList.push(aoiDatas);
    })
    setAoisListData(aoisList);
  }

  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [quantitySearchDate, setQuantitySearchDate] = useState({start: '', end: ''});
  const [selectedSelectBoxStartDate, setSelectedSelectBoxStartDate] = useState();
  const [selectedSelectBoxEndDate, setSelectedSelectBoxEndDate] = useState();

  /** 変位の日付入力欄(セレクトボックス)に変更があったときの処理 */
  const handleChangeQuantityDateSelectBox = (date, startOrEnd) => {
    if (startOrEnd === 'start') {
      setSelectedSelectBoxStartDate(date.target.value);
    } else if (startOrEnd === 'end') {
      setSelectedSelectBoxEndDate(date.target.value);
    }
  }

  /** 凡例の値を保存 */
  //　画面描画時の値を定義
  const defaultLegendMin = debugFlag ? -20 : -(round(qmaxValue));
  const defaultLegendMax = debugFlag ? 20 : round(qmaxValue);
  // ドロワーに渡す用
  const [legendMin, setLegendMin] = useState(defaultLegendMin); // 変動速度 or 変位 の凡例のmin初期値
  const [legendMax, setLegendMax] = useState(defaultLegendMax); // 変動速度 or 変位 の凡例のmax初期値
  // 最終的にMap側に渡す用
  const [selectedLegendMin, setSelectedLegendMin] = useState(legendMin); // 変動速度 or 変位 の凡例のmin
  const [selectedLegendMax, setSelectedLegendMax] = useState(legendMax); // 変動速度 or 変位 の凡例のmax

  /** スライドバーに変更があったときの処理 */
  const handleChangeSliderBar = (event, value) => {
    setLegendMax(value);
    setLegendMin(-(value));
  }

  /** 凡例スライダー横のテキストボックスに変更があったときの処理 */
  const handleInputChange = (event) => {
    let inputNum = event.target.value;
    setLegendMax(inputNum === '' ? '' : Number(inputNum));
    setLegendMin(-(inputNum === '' ? '' : Number(inputNum)));
  }

  /** 凡例スライダー横のテキストボックスの最大値最小値を設定する処理 */
  const handleBlur = (outputType) => {
    //凡例が変動速度の場合
    if(outputType === 'velocity'){
      if (legendMax < 0 || legendMax === '') {
        setLegendMax(0);
        setLegendMin(0);
      } else if (legendMax > 50) {
        setLegendMax(50);
        setLegendMin(-50);
      }
    }
    //凡例が期間変位の場合
    if(outputType === 'quantity'){
      if (legendMax < 0 || legendMax === '') {
        setLegendMax(0);
        setLegendMin(0);
      } else if (legendMax > 250) {
        setLegendMax(250);
        setLegendMin(-250);
      }
    }
  };

  /** Drawer内のグラフの最大値の設定 */
  const [graphMaxConfig, setGraphMaxConfig] = useState('auto'); //最大値の設定 or リセット
  const [graphMaxValue, setGraphMaxValue] = useState(0); //入力されたグラフの最大値
  /** Drawer内のグラフの最小値の設定 */
  const [graphMinConfig, setGraphMinConfig] = useState('auto'); //最小値の設定 or リセット
  const [graphMinValue, setGraphMinValue] = useState(0); //入力されたグラフの最小値
  /** Drawer内の各グラフの表示の設定 */
  const [graphShowHideConfig, setGraphShowHideConfig] = useState({displace: true, speed: false});
  const handleChangeGraphShowHideConfig = useCallback((e, value) => {
    let checked = e.target.checked;
    if(value === "displace") {
      if (graphShowHideConfig.speed === false && checked === false) return; // 必ずどちらかがONの状態にする
      setGraphShowHideConfig((prevState) => ({ ...prevState, displace: checked }))
    }
    if(value === "speed") {
      if (graphShowHideConfig.displace === false && checked === false) return; // 必ずどちらかがONの状態にする
      setGraphShowHideConfig((prevState) => ({ ...prevState, speed: checked }))
    }
  },[graphShowHideConfig])

  // グラフの最小値の更新処理
  const handleChangeMinValue = (value) => {
    setGraphMinValue(value);
  }
  // グラフの最大値の更新処理
  const handleChangeMaxValue = (value) => {
    setGraphMaxValue(value);
  }

  // グラフの最小値最大値の設定処理
  const handleClickGraphMinMaxConfigButton = () => {
    setGraphMinConfig(Number(graphMinValue));
    setGraphMaxConfig(Number(graphMaxValue));
  }

  //グラフの最小値最大値のリセット処理
  const handleClickGraphMinMaxResetButton = () => {
    setGraphMinConfig('auto');
    setGraphMinValue(0);
    setGraphMaxConfig('auto')
    setGraphMaxValue(0);
  }

  const [quantityDates, setQuantityDates] = useState(); // 変位の開始日、終了日の候補となる日付
  const [aoisGeojson, setAoisGeojson] = useState(null); // プロジェクトに紐づくAOIのgeojson

  // カスタムフック
  const { aoiNameInputValue, aoiIds, isFilterModal, filterSearchFlag, filterExecuteFlag, filteringAois, aoiPeriodValue, aoiLevelValue, filterInputFlag, purchaseFlag, setIsFilterModal, handleChangeInputFilterAoiName, setFilterExecuteFlag, handleClickAoiFilterModalBtn, handleChangeSearchFlag, handleClickFilterBtn, handleClickFilterResetBtn, handleChangeFilterPeriod, handleChangeFilterLevel, handleChangeFilterPurchaseCheck } = useAoiFiltering(aoisGeojson, aoiPurchaseData);
  // 解析点の状態を変更する処理
  const { analysisStateChangeFlag, nowAnalysisPointState, handleChangeAnalysisPointState, analysisStateChangeButtonState, handleClickSelectDateArrowBtn, quantityPrevNextDisableFlag } = useConfigAnalysisPointState(analysisPointSize, AnalyticeModeType, selectedSelectBoxStartDate, selectedSelectBoxEndDate, setQuantitySearchDate, setSelectedLegendMin, setSelectedLegendMax, legendMin, quantityDates, setSelectedSelectBoxEndDate, setSelectedSelectBoxStartDate);

  /** 画面初期描画時  */
  useEffect(() => {
    if (Object.keys(formatGeojson.features).length === 0) return;
    // 選択中のProjectのAOI日付一覧をGeojsonから取得
    const fetchAoisGeoJson = () => {
      const aoisGeojson = formatGeojson; // AOIのGeojson取得
      setAoisGeojson(aoisGeojson);
      const aoiDates = projectDates; // AOI日付一覧
      const startDate = aoiDates[0]; // AOI日付一覧から最初の日付
      const endDate = aoiDates[aoiDates.length - 1]; // AOI日付一覧から最後の日付
      const aoiDataList = aoisGeojson.features;
      const updatedAoiDates = aoiDates.map(aoiDate => aoiDate.replace(/\/0/, '/').replace(/\/0/, '/'));
      setQuantityDates(updatedAoiDates);
      setSelectedStartDate(new Date (startDate));
      setSelectedEndDate(new Date (endDate));
      setSelectedSelectBoxStartDate(startDate);
      setSelectedSelectBoxEndDate(endDate);
      setQuantitySearchDate({start: startDate, end: endDate});
      setAoisTableData(createAoiWindowTableData(aoiDataList, endDate));
      setAoisData(aoiDataList);
      getDateFromGeojson(startDate, endDate);
    }
    fetchAoisGeoJson();

    /** Map Layer を取得 */
    fetchMapLayers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatGeojson]);

  // 初回のgeojson取得完了フラグを更新
  useEffect(() =>{
    if(aoisGeojson === null) return;
    setIsFetchfirstAoigeojson(true);
  },[aoisGeojson])

  useEffect(() => {
    /** ドロワーメニューの設定 */
    prepareDrawerMenu(aoisData, rasterInfoList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoisData, rasterInfoList]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ユーザーがドロワメニューで設定した値をDBに保存するロジック
  // const {fetchAddUserConfig} = useAddUserConfig(
  //   projectId,
  //   user.attributes.email,
  //   aoiNameInputValue,
  //   aoiPeriodValue,
  //   aoiLevelValue,
  //   filterSearchFlag,
  // )

  return (
    <>
      <IconButton className={classes.iconButton} edge="start" color="inherit" onClick={handleClick}>
        <AccountCircleRoundedIcon />
      </IconButton>
      <Menu
        style={{ top: '20px' }}
        className="accountMenu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem><GroupIcon className={classes.accoutIcon} />{accountGroupName && accountGroupName}</MenuItem>
        <MenuItem><PersonIcon className={classes.accoutIcon} />{userName && userName}</MenuItem>
        <MenuItem><MailIcon className={classes.accoutIcon} />{user.attributes && user.attributes.email}</MenuItem>
        <AmplifySignOut onClick={handleClose}></AmplifySignOut>
        <div className={classes.versionTxt}>バージョン:0.4.0</div>
      </Menu>
      <AnalysisPointFilteringModal
        analysisFilteringModalIsOpen={analysisFilteringModalIsOpen}
        handleChangeAnalysisFilteringModalClose={handleChangeAnalysisFilteringModalClose}
        filteringSubjects={filteringSubjects}
        filtergingConditions={filtergingConditions}
        analysisFilteringArrays={analysisFilteringArrays}
        handleClickAddfilteringRecord={handleClickAddfilteringRecord}
        handleClickRemovefilteringRecord={handleClickRemovefilteringRecord}
        addFilteringFlag={addFilteringFlag}
        handleChangeFilteringTextSelectInput={handleChangeFilteringTextSelectInput}
        handleClickAnalysisPonitFilteringFlag={handleClickAnalysisPonitFilteringFlag}
        handleClickAnalysisPonitFilteringResetFlag={handleClickAnalysisPonitFilteringResetFlag}
      />
      <UploadRasterNameModal
        rasterNameInputModalIsOpen={rasterNameInputModalIsOpen}
        handleClickRasterNameInputModalClose={handleClickRasterNameInputModalClose}
        handleClickRasterNameInput={handleClickRasterNameInput}
        handleClickRasterDataPutS3={handleClickRasterDataPutS3}
      />
      <Drawer
        t={t}
        aoisData={aoisData}
        isDrawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        outputType={outputType}
        handleChangeOutputType={handleChangeOutputType}
        mapStyle={mapStyle}
        handleChangeMapStyle={handleChangeMapStyle}
        mapLayerState={mapLayerState}
        handleChangeMapLayerCheckbox={handleChangeMapLayerCheckbox}
        objectLayerState={objectLayerState}
        handleChangeObjectLayerCheckbox={handleChangeObjectLayerCheckbox}
        aoisState={aoisState}
        aoisListData={aoisListData}
        handleChangeAoisState={handleChangeAoisStateWithCheckbox}
        isAllAoisChecked={isAllAoisChecked}
        handleClickAoiZoomButton={handleClickAoiZoomButton}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        quantitySearchDate={quantitySearchDate}
        handleChangeQuantityDateSelectBox={handleChangeQuantityDateSelectBox}
        selectedSelectBoxStartDate={selectedSelectBoxStartDate}
        selectedSelectBoxEndDate={selectedSelectBoxEndDate}
        quantityDates={quantityDates}
        handleChangeSliderBar={handleChangeSliderBar}
        legendMin={legendMin}
        legendMax={legendMax}
        selectedLegendMax={selectedLegendMax}
        handleClickGraphMinMaxConfigButton={handleClickGraphMinMaxConfigButton}
        handleClickGraphMinMaxResetButton={handleClickGraphMinMaxResetButton}
        handleChangeMaxValue={handleChangeMaxValue}
        handleChangeMinValue={handleChangeMinValue}
        graphMaxValue={graphMaxValue}
        graphMinValue={graphMinValue}
        selectRankPeriod={selectRankPeriod}
        selectRankLevel={selectRankLevel}
        handleChangeSelectRankPeriod={handleChangeSelectRankPeriod}
        handleChangeSelectRankLevel={handleChangeSelectRankLevel}
        selectRankPeriodErrorFlag={selectRankPeriodErrorFlag}
        selectRankLevelErrorFlag={selectRankLevelErrorFlag}
        handleChangeAoiListSort={handleChangeAoiListSort}
        handleChangeAoiListReset={handleChangeAoiListReset}
        selectInsideColor={selectInsideColor}
        handleChangeSelectInsideColor={handleChangeSelectInsideColor}
        handleClickAoiInsideColorChange={handleClickAoiInsideColorChange}
        memoItems={memoItems}
        handleClickMemoZoom={handleClickMemoZoom}
        handleChangeInputSeachAoiName={handleChangeInputSeachAoiName}
        handleClickSeachAoiName={handleClickSeachAoiName}
        handleClickSortAoiNameReset={handleClickSortAoiNameReset}
        latitudeCenter={latitudeCenter}
        longitudeCenter={longitudeCenter}
        handleClickConfigLatLng={handleClickConfigLatLng}
        configUserLatLngDatas={configUserLatLngDatas}
        handleClickAddResetUserLatLng={handleClickAddResetUserLatLng}
        handleChangeAoisStateOnOffBtn={handleChangeAoisStateOnOffBtn}
        commonMapLayerOpacity={commonMapLayerOpacity}
        handleChangeCommonMapLayerOpacity={handleChangeCommonMapLayerOpacity}
        AnalyticeModeType={AnalyticeModeType}
        isSlider={isSlider}
        handleChangeAnalyticeMode={handleChangeAnalyticeMode}
        usageGuideunit={usageGuideunit}
        ModeTypes={ModeTypes}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        weaterToggleFlag={weaterToggleFlag}
        weatherToggleChecked={weatherToggleChecked}
        qmaxValue={qmaxValue}
        showWeaterItem={showWeaterItem}
        raineyToggleFlag={raineyToggleFlag}
        raineyToggleChecked={raineyToggleChecked}
        weatherLoadingName={weatherLoadingName}
        handleClickAoiFilterModalBtn={handleClickAoiFilterModalBtn}
        filterExecuteFlag={filterExecuteFlag}
        filteringAois={filteringAois}
        filterInputFlag={filterInputFlag}
        handleClickGridFilterModalBtn={handleClickGridFilterModalBtn}
        projectAoiType={projectAoiType}
        handleChangeAnalysisPointSize={handleChangeAnalysisPointSize}
        analysisPointSize={analysisPointSize}
        handleChangeAnalysisPointState={handleChangeAnalysisPointState}
        analysisStateChangeButtonState={analysisStateChangeButtonState}
        handleClickSelectDateArrowBtn={handleClickSelectDateArrowBtn}
        quantityPrevNextDisableFlag={quantityPrevNextDisableFlag}
        isAbsMode={isAbsMode}
        handleChangeAbsMode={handleChangeAbsMode}
        graphShowHideConfig={graphShowHideConfig}
        handleChangeGraphShowHideConfig={handleChangeGraphShowHideConfig}
        commonMapLayersState={commonMapLayersState}
        handleClickCommonMapLayers={handleClickCommonMapLayers}
        handleChangeAnalysisFilteringModalOpen={handleChangeAnalysisFilteringModalOpen}
        opacityTypes={opacityTypes}
        handleChangeAnalysisOpacity={handleChangeAnalysisOpacity}
        AnalyticeModeOpacityType={AnalyticeModeOpacityType}
        handleClickNowZoomLevel={handleClickNowZoomLevel}
        nowZoomLevel={nowZoomLevel}
        handleChangeSelectRasterFile={handleChangeSelectRasterFile}
        handleClickUploadBtn={handleClickUploadBtn}
        fileInputRef={fileInputRef}
      ></Drawer>
      <main>
        <MapboxMap
          t={t}
          currentLang={currentLang}
          user={user}
          myProjectId={myProjectId}
          projectId={projectId}
          aoisGeojson={aoisGeojson}
          mapLoading={mapLoading}
          setMapLoading={setMapLoading}
          aoisTableData={aoisTableData}
          outputType={outputType}
          mapStyle={mapStyle}
          mapInstanceStyle={mapInstanceStyle}
          mapLayerState={mapLayerState}
          mapLayersUrls={mapLayersUrls}
          objectLayerState={objectLayerState}
          setObjectLayerState={setObjectLayerState}
          aoisState={aoisState}
          handleChangeAoisState={handleChangeAoisStateWithAoiClick}
          aoisData={aoisData}
          latitudeCenter={latitudeCenter}
          longitudeCenter={longitudeCenter}
          zoom={zoom}
          zoomAoiId={zoomAoiId}
          setZoomAoiId={setZoomAoiId}
          quantityDates={quantityDates}
          quantitySearchDate={quantitySearchDate}
          selectedLegendMin={selectedLegendMin}
          selectedLegendMax={selectedLegendMax}
          graphMaxConfig={graphMaxConfig}
          graphMinConfig={graphMinConfig}
          sortAoiDataIdList={sortAoiDataIdList}
          sortResetAoiDataIdList={sortResetAoiDataIdList}
          prevAoiSearchIdList={prevAoiSearchIdList}
          setPrevAoiSearchIdList={setPrevAoiSearchIdList}
          prevAoiDeleteIdList={prevAoiDeleteIdList}
          setPrevAoiDeleteIdList={setPrevAoiDeleteIdList}
          setSelectRankPeriod={setSelectRankPeriod}
          setSelectRankLevel={setSelectRankLevel}
          setSelectRankPeriodErrorFlag={setSelectRankPeriodErrorFlag}
          setSelectRankLevelErrorFlag={setSelectRankLevelErrorFlag}
          selectInsideColor={selectInsideColor}
          aoiInsideColorBtn={aoiInsideColorBtn}
          prevInsideColorId={prevInsideColorId}
          setprevInsideColorId={setprevInsideColorId}
          memoItems={memoItems}
          memoStatusCode={memoStatusCode}
          memoErrorMessage={memoErrorMessage}
          memoGetFirstFlag={memoGetFirstFlag}
          setMemoGetFirstFlag={setMemoGetFirstFlag}
          memoZoomItem={memoZoomItem}
          setMemoItems={setMemoItems}
          setMemoStatusCode={setMemoStatusCode}
          nowLocationLatLng={nowLocationLatLng}
          nowLocationLoadingFlag={nowLocationLoadingFlag}
          handleClickNowLocation={handleClickNowLocation}
          accountGroupId={accountGroupId}
          handleChangeInputAdminComment={handleChangeInputAdminComment}
          handleClickAdminCommentKeep={handleClickAdminCommentKeep}
          setAdminCommentAoiId={setAdminCommentAoiId}
          aoiClickFlag={aoiClickFlag}
          setAoiClickFlag={setAoiClickFlag}
          registerAdminCommentLoadingFlag={registerAdminCommentLoadingFlag}
          adminComment={adminComment}
          getAdminCommentAlertFlag={getAdminCommentAlertFlag}
          setGetAdminCommentAlertFlag={setGetAdminCommentAlertFlag}
          fetchAoiAdminComment={fetchAoiAdminComment}
          mapNowLatLngFlag={mapNowLatLngFlag}
          setMapNowLatLngData={setMapNowLatLngData}
          setMapNowLatLngFlag={setMapNowLatLngFlag}
          setConfigUserLatLngDatas={setConfigUserLatLngDatas}
          mapRegsiterLatLngData={mapRegsiterLatLngData}
          regsiterMessageFlag={regsiterMessageFlag}
          setRegsiterMessageFlag={setRegsiterMessageFlag}
          regsiterMessage={regsiterMessage}
          isSuccess={isSuccess}
          mapNowLatLngData={mapNowLatLngData}
          regsiterResetFlag={regsiterResetFlag}
          regsiterFetchFlag={regsiterFetchFlag}
          setRegsiterFetchFlag={setRegsiterFetchFlag}
          adminCommentResultMessage={adminCommentResultMessage}
          isSuccessAdminComment={isSuccessAdminComment}
          summaryTextData={summaryTextData}
          setSummaryTextData={setSummaryTextData}
          zenrinMapLoading={zenrinMapLoading}
          setZenrinMapLoading={setZenrinMapLoading}
          zenrinFetchErrorFlag={zenrinFetchErrorFlag}
          zenrinErrorMessage={zenrinErrorMessage}
          setZenrinFetchErrorFlag={setZenrinFetchErrorFlag}
          znetFetchErrorFlag={znetFetchErrorFlag}
          setZnetFetchErrorFlag={setZnetFetchErrorFlag}
          znetErrorMessage={znetErrorMessage}
          znetMapLoading={znetMapLoading}
          setZnetMapLoading={setZnetMapLoading}
          resetRegisterAdminComment={resetRegisterAdminComment}
          commonMapLayerOpacity={commonMapLayerOpacity}
          handleChangeGeologyDataMode={handleChangeGeologyDataMode}
          GeologyDataGetMode={GeologyDataGetMode}
          setgeologyDataGetMode={setgeologyDataGetMode}
          AnalyticeModeType={AnalyticeModeType}
          memoUpdateTitle={memoUpdateTitle}
          memoUpdateText={memoUpdateText}
          memoUpdateId={memoUpdateId}
          memoUpdateFormStatus={memoUpdateFormStatus}
          isMemoTitleUpdateValid={isMemoTitleUpdateValid}
          isMemoFormUpdateValid={isMemoFormUpdateValid}
          isUpdateAlertFormOpen={isUpdateAlertFormOpen}
          alertUpdateMessageMemoForm={alertUpdateMessageMemoForm}
          upDateLoadingMemo={upDateLoadingMemo}
          isUpdateMemoSuccess={isUpdateMemoSuccess}
          updateMemo={updateMemo}
          handleUpdatePopupCancelButton={handleUpdatePopupCancelButton}
          openUpdateMemoPopup={openUpdateMemoPopup}
          handleChangeUpdateMemoTitleValidate={handleChangeUpdateMemoTitleValidate}
          handleChangeUpdateMemoFormValidate={handleChangeUpdateMemoFormValidate}
          AnalyticeIsShowAoi={AnalyticeIsShowAoi}
          setAnalyticeIsShowAoi={setAnalyticeIsShowAoi}
          handleChangeAoisStateOnOffBtn={handleChangeAoisStateOnOffBtn}
          usageGuideunit={usageGuideunit}
          getDarkColor={getDarkColor}
          isSnackbar={isSnackbar}
          snackbarColor={snackbarColor}
          snackbarMessage={snackbarMessage}
          handleCloseSnackbar={handleCloseSnackbar}
          weaterObservationData={weaterObservationData}
          weatherPopupStatus={weatherPopupStatus}
          handleClickIsWeatherPopup={handleClickIsWeatherPopup}
          weaterObservationDataFetchFlag={weaterObservationDataFetchFlag}
          observationPopupContent={observationPopupContent}
          handleClickCloseWeatherPopup={handleClickCloseWeatherPopup}
          fetchWeatherData={fetchWeatherData}
          observationPointData={observationPointData}
          raineyToggleFlag={raineyToggleFlag}
          weatherLoadingFlag={weatherLoadingFlag}
          aoiIds={aoiIds}
          filterExecuteFlag={filterExecuteFlag}
          setIsFilterModal={setIsFilterModal}
          setFilterExecuteFlag={setFilterExecuteFlag}
          isFilterModal={isFilterModal}
          filterSearchFlag={filterSearchFlag}
          filteringAois={filteringAois}
          aoiPeriodValue={aoiPeriodValue}
          aoiLevelValue={aoiLevelValue}
          handleClickFilterResetBtn={handleClickFilterResetBtn}
          handleChangeSearchFlag={handleChangeSearchFlag}
          handleClickFilterBtn={handleClickFilterBtn}
          handleChangeInputFilterAoiName={handleChangeInputFilterAoiName}
          handleClickAoiFilterModalBtn={handleClickAoiFilterModalBtn}
          handleChangeFilterPeriod={handleChangeFilterPeriod}
          handleChangeFilterLevel={handleChangeFilterLevel}
          aoiNameInputValue={aoiNameInputValue}
          filterInputFlag={filterInputFlag}
          aoiPurchaseData={aoiPurchaseData}
          projectAoiType={projectAoiType}
          isShowSnakbar={isShowSnakbar}
          handleChangePurchaseFill={handleChangePurchaseFill}
          handleChangePurchaseOutLine={handleChangePurchaseOutLine}
          purchaseFlag={purchaseFlag}
          handleChangeFilterPurchaseCheck={handleChangeFilterPurchaseCheck}
          aoiPurchaseExeFlag={aoiPurchaseExeFlag}
          setAoiPurchaseExeFlag={setAoiPurchaseExeFlag}
          isShowPurchaseAlertPopup={isShowPurchaseAlertPopup}
          isGridFilterModal={isGridFilterModal}
          adjoinNum={adjoinNum}
          gridAoiShowType={gridAoiShowType}
          handleClickGridFilterModalBtn={handleClickGridFilterModalBtn}
          handleChangeGridFilterAdjon={handleChangeGridFilterAdjon}
          handleChangeGridFilterShowType={handleChangeGridFilterShowType}
          handleClickGridFilterExeBtn={handleClickGridFilterExeBtn}
          handleClickGridFilterReset={handleClickGridFilterReset}
          isFetchfirstAoigeojson={isFetchfirstAoigeojson}
          reFetchAoiLoading={reFetchAoiLoading}
          setReFetchAoiLoading={setReFetchAoiLoading}
          setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
          analysisPointSize={analysisPointSize}
          pjName={pjName}
          analysisStateChangeFlag={analysisStateChangeFlag}
          analysisVer={analysisVer}
          nowAnalysisPointState={nowAnalysisPointState}
          isAbsMode={isAbsMode}
          graphShowHideConfig={graphShowHideConfig}
          selectedSelectBoxStartDate={selectedSelectBoxStartDate}
          selectedSelectBoxEndDate={selectedSelectBoxEndDate}
          weaterToggleFlag={weaterToggleFlag}
          setWeaterMapCenterLatlng={setWeaterMapCenterLatlng}
          commonMapLayersState={commonMapLayersState}
          nowCheckCommonLayer={nowCheckCommonLayer}
          analysisFilteringArrays={analysisFilteringArrays}
          analysisFilteringFlag={analysisFilteringFlag}
          analysisFilteringResetFlag={analysisFilteringResetFlag}
          setAnalysisFilteringFlag={setAnalysisFilteringFlag}
          setAnalysisFilteringResetFlag={setAnalysisFilteringResetFlag}
          AnalyticeModeOpacityType={AnalyticeModeOpacityType}
          setNowZoomLevel={setNowZoomLevel}
          nowZoomLevelGetFlag={nowZoomLevelGetFlag}
          analysisFilteringIsActive={analysisFilteringIsActive}
          setAnalysisFilteringIsActive={setAnalysisFilteringIsActive}
        ></MapboxMap>
      </main>
    </>
  );
}
