import { useCallback, useState } from 'react';

//汎用レイヤーの表示状態を制御する処理
export const useCommonMapLayer = () => {
  // 各汎用レイヤーのチェックボックスの状態を管理
  const [commonMapLayersState, setCommonMapLayersState] = useState({
    geologyLayerState: false,
    disasterHazardType1State: false,
    disasterHazardType2State: false,
    disasterHazardType3State: false,
    jShisBosaiState: false,
    insarLayerType1: false,
    insarLayerType2: false,
    insarLayerType3: false,
    insarLayerType4: false,
  });
  // 選択した汎用レイヤーの情報を格納
  const [nowCheckCommonLayer, setNowCheckCommonLayer] = useState(false);

  // 汎用レイヤーの表示状態を変更する処理
  const handleClickCommonMapLayers = useCallback((event, type) => {
    const e = event.target.checked;
    setCommonMapLayersState((prevState) => ({
      ...prevState,
      [type]: e,
    }));
    setNowCheckCommonLayer(type);

    // シームレス地質図を表示中は凡例を調べるボタンを表示する
    if(type === "geologyLayerState"){
      if(e){
        document.getElementById('geologyCheck') && document.getElementById('geologyCheck').classList.add('buttonActive');
      }else{
        document.getElementById('geologyCheck') && document.getElementById('geologyCheck').classList.remove('buttonActive');
      }
    }
  },[])

  //地質図の不透明度を定義
  const [commonMapLayerOpacity, setCommonMapLayerOpacity] = useState(60);
  const handleChangeCommonMapLayerOpacity = (event, newValue) => {
    setCommonMapLayerOpacity(newValue)
  }

  /** 地質図の凡例情報を取得できる状態をON,OFFで管理 */
  const[GeologyDataGetMode, setgeologyDataGetMode] = useState(false); // 凡例情報取得モードON,OFFのフラグ
  // 凡例情報取得ボタン押下時の処理
  const handleChangeGeologyDataMode = (value) => {
    setgeologyDataGetMode(!value);
  }

  return{
    commonMapLayerOpacity,
    GeologyDataGetMode,
    setgeologyDataGetMode,
    handleChangeCommonMapLayerOpacity,
    handleChangeGeologyDataMode,
    commonMapLayersState,
    handleClickCommonMapLayers,
    nowCheckCommonLayer
  }
}