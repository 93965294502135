import { useCallback, useState } from "react";

// 解析点をフィルタリングを行う処理
export const useAnalysisPointFiltering = (analysisVer, debugFlag) => {
   // モーダルの開閉状態を制御
  const [analysisFilteringModalIsOpen, setAnalysisFilteringModalIsOpen] = useState(false);
  const handleChangeAnalysisFilteringModalOpen = useCallback(() => setAnalysisFilteringModalIsOpen(true),[]); // モーダル開く処理
  const handleChangeAnalysisFilteringModalClose = useCallback(() => setAnalysisFilteringModalIsOpen(false),[]); // モーダル閉じる処理

  // フィルタリング関連
  const [analysisFilteringArrays, setAnalysisFilteringArrays] = useState([{ active: false, subject: "", conditions: "", value: ""}]); // 選択した解析点をフィルタリングする対象をstateに保存する処理
  const [addFilteringFlag, setAddFilteringFlag] = useState(false); // フィルタリング項目追加ボタンの非活性フラグ
  const [analysisFilteringFlag, setAnalysisFilteringFlag] = useState(false); // フィルタリングの実行フラグ
  const [analysisFilteringResetFlag, setAnalysisFilteringResetFlag] = useState(false); // フィルタリングのリセットフラグ
  const [analysisFilteringIsActive, setAnalysisFilteringIsActive] = useState(false); // フィルタリング中かどうか管理するフラグ

  // 解析点をフィルタリングする対象の配列を定義
  let filteringSubjects = [];
  if(analysisVer >= 3.0){
    filteringSubjects = [
      {key: "変動速度", value: "v"},
      {key: "標高", value: "h"},
      {key: "勾配の向き", value: "d"},
      {key: "勾配の角度", value: "g"},
      ...(debugFlag ? [{key: "*信頼度", value: "c"}] : []) // 信頼度
    ]
  }else if(analysisVer >= 2.0){
    filteringSubjects = [
      {key: "変動速度", value: "v"},
      {key: "標高", value: "h"},
      {key: "勾配の向き", value: "d"},
      {key: "勾配の角度", value: "g"},
    ]
  }else if(analysisVer >= 1.0){
    filteringSubjects = [
      {key: "変動速度", value: "v"},
    ]
  }

  // 解析点のフィルタリング条件を配列を定義
  const filtergingConditions = [
    {operator: ">=", value: "次以上"},
    {operator: "<=", value: "次以下"},
    {operator: ">", value: "次より大きい"},
    {operator: "<", value: "次より小さい"}
  ]

  // フィルタリング項目の各入力項目を変更した際の処理
  const handleChangeFilteringTextSelectInput = useCallback((e, target, index) => {
    setAnalysisFilteringArrays(prevState => 
      prevState.map((item, i) => {
        if (i === index) {
          // 指定された項目を更新
          const updatedItem = { ...item, [target]: e };
          // subject, conditions, valueがすべて空でないかチェック
          const allFieldsFilled = updatedItem.subject !== "" && updatedItem.conditions !== "" && updatedItem.value !== "";
          // targetが"active"の場合、activeの値を更新
          if (target === "active") {
            return { ...updatedItem, active: allFieldsFilled ? e : item.active };
          }
          // それ以外の場合、allFieldsFilledがtrueならactiveをtrueに設定
          return { ...updatedItem, active: allFieldsFilled };
        }
        return item; // それ以外のオブジェクトはそのまま
      })
    );
  }, []);

  // フィルタリングを実行する処理
  const handleClickAnalysisPonitFilteringFlag = useCallback(() => {
    setAnalysisFilteringFlag(true); //　フィルタリングを実行
    setAnalysisFilteringIsActive(true); // フィルタリング実行中フラグをON
  },[])
  // フィルタリングをリセットする処理
  const handleClickAnalysisPonitFilteringResetFlag = useCallback(() => {
    setAnalysisFilteringArrays(prevState => prevState.slice(0, 1)); // 先頭の配列以外を削除する
    setAnalysisFilteringArrays(prevState => prevState.map(item => ({...item, active: false, subject: "", conditions: "", value: ""}))); // 先頭の配列の入力項目をリセットする
    setAnalysisFilteringResetFlag(true); // フィルタリングのリセットフラグ
    setAnalysisFilteringIsActive(false); // フィルタリング実行中フラグをOFF
  });

  // フィルタリング項目を追加する処理
  const handleClickAddfilteringRecord = useCallback(() => {
    // フィルタリング項目数に達した場合は追加ボタンを非活性にする
    if(analysisFilteringArrays.length >= filteringSubjects.length * 2) {
      setAddFilteringFlag(true);
      return;
    }
    // 項目(レコード)を追加
    setAnalysisFilteringArrays(prevState => [
      ...prevState, 
      { active: false, subject: "", conditions: "", value: "" }
    ]);
  },[analysisFilteringArrays])

  // フィルタリング項目を削除する処理
  const handleClickRemovefilteringRecord = useCallback((indexToRemove) => {
    if(analysisFilteringArrays.length === 1) return; // 最後の1つは削除せずに処理を停止

    // 項目(レコード)を削除
    setAnalysisFilteringArrays(prevState =>
      prevState.filter((_, index) => index !== indexToRemove)
    );
  },[analysisFilteringArrays])

  return {
    analysisFilteringModalIsOpen,
    handleChangeAnalysisFilteringModalOpen,
    handleChangeAnalysisFilteringModalClose,
    filteringSubjects,
    filtergingConditions,
    analysisFilteringArrays,
    handleClickAddfilteringRecord,
    handleClickRemovefilteringRecord,
    addFilteringFlag,
    handleChangeFilteringTextSelectInput,
    handleClickAnalysisPonitFilteringFlag,
    handleClickAnalysisPonitFilteringResetFlag,
    analysisFilteringFlag,
    analysisFilteringResetFlag,
    setAnalysisFilteringFlag,
    setAnalysisFilteringResetFlag,
    analysisFilteringIsActive,
    setAnalysisFilteringIsActive,
  };
};