import React, { useCallback, useRef } from 'react';
import { Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import 'css/Chart.scss';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import { debugFlagState } from 'atoms/projectData';

const useStyles = makeStyles(() => ({
  ResponsiveContainer: {
    position: 'relative',
    top: '-15px',
    width: '100%',
    height: '100%',
  },
  customTooltip: {
    background: 'rgba(0, 78, 162, 0.3)',
    borderRadius: '5px',
    fontSize: '12px',
    lineHeight: 1.6,
    padding: '5px',
  },
  customTooltipLabel: {
    margin: 0,
  },
  customTooltipLabelType1: {
    margin: 0,
    color: '#004ea2',
    fontWeight: 'bold',
  },
  customTooltipLabelType2: {
    margin: 0,
    color: '#9d5b8b',
    fontWeight: 'bold',
  }
}));

const Chart = (props) => {
  const {
    t,
    pointDatasState,
    graphMaxConfig,
    graphMinConfig,
    observationPointData,
    raineyToggleFlag,
    graphShowHideConfig,
    selectedSelectBoxStartDate,
    selectedSelectBoxEndDate
  } = props;

  //Recoilから取得
  const debugFlag = useRecoilValue(debugFlagState);

  const raineyToggleFlagRef = useRef();
  raineyToggleFlagRef.current = raineyToggleFlag; // selectedLegendMaxの最新の状態を参照する
  const observationPointDataRef = useRef();
  observationPointDataRef.current = observationPointData; // selectedLegendMaxの最新の状態を参照する

  const classes = useStyles();

  /** グラフのTooltip用のhtml生成 */
  const CustomTooltip = useCallback(({ payload, label, active }) => {
    if (active && label) {
      const graphNum = payload.length;
      return (
        <div className={classes.customTooltip}>
          <p className={classes.customTooltipLabel}>{new Date(label).toLocaleDateString()}</p>
          {graphNum === 2 &&
            <>
              <p className={classes.customTooltipLabelType1}>{Math.floor(payload[0].value * Math.pow(10, 5)) / Math.pow(10, 5)}</p>
              <p className={classes.customTooltipLabelType2}>{Math.floor(payload[1].value * Math.pow(10, 5)) / Math.pow(10, 5)}</p>
            </>
          }

          {graphNum === 1 && (
            <>
              {graphShowHideConfig.displace && <p className={classes.customTooltipLabelType1}>{Math.floor(payload[0].value * Math.pow(10, 5)) / Math.pow(10, 5)}</p>}
              {graphShowHideConfig.speed && <p className={classes.customTooltipLabelType2}>{Math.floor(payload[0].value * Math.pow(10, 5)) / Math.pow(10, 5)}</p>}
            </>
          )}
        </div>
      );
    }
    return null;
  },[graphShowHideConfig])

  // 期間変動速度で選択している期間内はdotの形を変更する処理
  const CustomizedDot = useCallback((props) => {
    const { cx, cy, payload } = props;
  
    // 日付で選択されている開始日と終了日ミリ秒に変換
    const unixStartDate = new Date(selectedSelectBoxStartDate).getTime();
    const unixEndDate = new Date(selectedSelectBoxEndDate).getTime();
    // グラフ内のX軸の各日付を取得
    const graphXDate = payload.date;
    
    // 期間変動速度で選択している期間内のdot(塗りつぶしdot)
    if (graphXDate >= unixStartDate) {
      if(graphXDate <= unixEndDate){
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            viewBox="0 0 1024 1024"
          >
            <circle cx="517.12" cy="517.12" r="150" fill="#9d5b8b" stroke="#9d5b8b" stroke-width="50" />
          </svg>
        );
      }
    }
  
    // 通常のdot
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        viewBox="0 0 1024 1024"
      >
        <circle cx="517.12" cy="517.12" r="150" fill="#fff" stroke="#9d5b8b" stroke-width="50" />
      </svg>
    );
  },[selectedSelectBoxStartDate, selectedSelectBoxEndDate])

  /**
  * 解析点のデータと雨量データを合体させてグラフに渡す処理
  * @param {Array} pointData 変位の値
  * @return {Array} 解析データと雨量データを時系列順にした配列 or pointDataをそのまま返す
  */
  const mergePointWeatherData = (pointData) => {

    if(raineyToggleFlagRef.current){
      // 降水量累積(mm・月)用のグラフデータを作成
      const raineyData = observationPointDataRef.current.map((value) => {
        let obj = {
          date: value.date * 1000,
          quantity: null,
          quantitySpeed: value.quantitySpeed,
          precipitation_total: value.precipitation_total,
          precipitation_max: value.precipitation_max
        }
        return obj;
      })
      // 降水量最大(mm/日)用のグラフデータを作成
      const quantityData = pointData.map((value) => {
        let obj = {
          date: value.date,
          quantity: value.quantity,
          quantitySpeed: value.quantitySpeed,
          precipitation_total: null,
          precipitation_max: null
        }
        return obj;
      })

      // 雨量データと変異の値をマージかつ時系列順に並び替え
      const mergedArray = raineyData.concat(quantityData).sort((a, b) => a.date - b.date);
      return mergedArray;
    }
    return pointData
  }

  return (
    <ResponsiveContainer className={classes.ResponsiveContainer}>
      <ComposedChart
        data={mergePointWeatherData(pointDatasState)}
        margin={{
          top: 5,
          right: 30,
          left: 5,
          bottom: 5,
        }}
        fontSize={12}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          domain={['dataMin', 'dataMax']}
          tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
          type='number'
          style={{fontSize: '0.9rem'}}
        />
        <YAxis
          yAxisId="left"
          stroke="#333"
          type="number"
          allowDataOverflow={true}
          domain={[graphMinConfig, graphMaxConfig]}
          label={{ value: 'mm', angle: 0, position: 'insideBottomRight', offset: 35, dy: 35, fill: '#6a6a6a' }}
        />
        <YAxis
          yAxisId="right" orientation="right"
          label={{ value: 'mm', angle: 0, position: 'insideBottomRight',dy: 7, display: raineyToggleFlag ? 'block' : 'none', fill: '#6a6a6a' }}
        />
        <Tooltip content={<CustomTooltip />}/>
        <Legend verticalAlign="top" />
        { graphShowHideConfig.displace && <Line name={t("map.displacement") + "(mm)"} isAnimationActive={false} yAxisId="left" connectNulls type="basis" dataKey="quantity" stroke="#004ea2" activeDot={{ r: 8 }} />}
        { graphShowHideConfig.speed && <Line name="期間変動速度(mm/年)" dot={<CustomizedDot />} isAnimationActive={false} yAxisId="left" connectNulls type="basis" dataKey="quantitySpeed" stroke="#9d5b8b" activeDot={{ r: 8 }} /> }
        { raineyToggleFlag && <Area name='降水量累積(mm・月)' isAnimationActive={false} yAxisId="right" connectNulls type="monotone" dataKey="precipitation_total" stroke="#8884d8" fill="#8884d8" activeDot={{ r: 8 }} />}
        { raineyToggleFlag && <Area name='降水量最大(mm/日)' isAnimationActive={false} yAxisId="right" connectNulls type="monotone" dataKey="precipitation_max" stroke="blue" fill="blue" activeDot={{ r: 8 }} /> }
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default Chart;