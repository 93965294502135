import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  legend: {
    display: 'flex',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    zIndex: '10',
    color: '#fff',
  },
  legend__header_unit: {
    fontSize: '.8rem',
    marginLeft: '.2rem',
  },
  legend__corlor__bar: {
    border: '1px solid #ddd',
    borderRadius: '2px',
    width: '1rem',
    height: '8rem',
  },
  legend__corlor__ground: { // 計測値
    background: 'linear-gradient(180deg, rgba(255,255,0,1) 0%, rgba(195,255,0,1) 25%, rgba(3,255,0,1) 50%, rgba(0,241,255,1) 75%, rgba(0,41,255,1) 100%)',
  },
  legend__corlor__slope: { // ランク
    background: 'linear-gradient(180deg, #7e017c 0%, #7e017c 20%, #fb00ff 20%, #fb00ff 40%, #fa0800 40%, #fa0800 60%, #fdfd12 60%, #fdfd12 80%, #0affff 80%, #0affff 100%)',
  },
  legend__corlor__gDirection: { // 勾配の向き
    background: 'linear-gradient(180deg, #ff0000 0%, #0000ff 25%, #03ff00 50%, #ffff00 75%, #ff0000 100%)'
  },
  legend__corlor__gSizeDirection: { // 勾配の角度
    background: 'linear-gradient(180deg, #ff0000 0%, #03ff00 100%)'
  },
  legend__corlor__cReliability: { // 勾配の角度
    background: 'linear-gradient(180deg, #0000ff 0%, #03ff00 50%, #e80000 100%)'
  },
  legend__numbers: {
    position: 'relative',
    left: '-15px',
  },
  legend__number: {
    display: 'block',
    fontSize: '.8rem',
    position: 'relative',
  },
  legend__number__min: {
    left: '-15px',
    position: 'absolute',
    bottom: '0',
  },
  legend__number__min0: {
    left: '-5px',
    position: 'absolute',
    bottom: '0',
  },
  legend__number__zero: {
    marginTop: '-8px',
    position: 'absolute',
    top: '50%',
  },
  legend__number__max: {
    position: 'absolute',
    top: '0',
    left: '-10px'
  },
  legend__number__max__left: {
    position: 'absolute',
    top: '0',
    left: '-10px'
  },
  legend__number__max__00: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  legend__number__max__01: {
    position: 'absolute',
    top: '55px',
    left: '-5px'
  },
  legend__number__min__slope: {
    left: '2px',
    position: 'absolute',
    bottom: '-5px',
  },
  legend__number__minHarf__slope: {
    left: '2px',
    position: 'absolute',
    bottom: '15%',
  },
  legend__number__zero__slope: {
    marginTop: '-8px',
    position: 'absolute',
    top: '60%',
    left: '-3px',
  },
  legend__number__maxHarf__slope: {
    position: 'absolute',
    top: '35%',
    left: '-5px'
  },
  legend__number__max__slope: {
    position: 'absolute',
    top: '20px',
    left: '-12px'
  },
  legend__direction: {
    position: 'absolute',
    left: '-5px'
  },
  unit: {
    fontSize: '12px',
    left: '-33px',
    position: 'absolute',
    top: '-22px',
    width: '74px',
  },
  text__color: {
    color: '#000 !important',
  },
}));

export default function Legend(props) {
  const {
    selectedLegendMin, // 凡例min
    selectedLegendMax, // 凡例max
    outputType, // quantity(変位) or velocity(変動速度)
    mapStyle, //マップの背景地図
    usageGuideunit, // 凡例の単位
    nowAnalysisPointState, // 現在の解析点の状態
  } = props;

  const classes = useStyles();
  const [unit, setUnit] = useState(''); // カラーバー上部の単位 

  //背景地図が白系のものを定義
  const mapStylesWhiteType = [
    'gsi_map',
    'mapbox://styles/mapbox/light-v10',
    'mapbox://styles/mapbox/outdoors-v11',
    'mapbox://styles/mapbox/streets-v11',
    'zenrin',
    'znet',
  ]

  //背景地図が白系だったら文字色を変更するフラグを更新
  let styleFlag = false;
  if (mapStylesWhiteType.includes(mapStyle)) styleFlag = true;

  //解析点の状態によってカラーバーの見た目を変更する処理
  useEffect(() => {
    // カラーバー上部の単位部分を定義する処理
    if(nowAnalysisPointState.type === "ground" || nowAnalysisPointState.type === "slope"){ // 色分け設定が計測値またはランクのとき
      if (outputType === 'quantity') setUnit('[mm]');
      if (outputType === 'velocity') setUnit(`[mm/${usageGuideunit}]`);
      if (outputType === 'quantitySpeed') setUnit(`[mm/年]`);
    }
    if(nowAnalysisPointState.type === 'gDirection' || nowAnalysisPointState.type === 'gSizeDirection' || nowAnalysisPointState.type === 'gSizeDirection' || nowAnalysisPointState.type === 'cReliability' || nowAnalysisPointState.type === 'none'){
      setUnit(''); //何も表示しない
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nowAnalysisPointState, outputType])

  return (
    <div className={`${classes.legend} ${styleFlag ? classes.text__color : ''}`}>
      <div className={classes.unit}>{unit}</div>

      {/* 解析点の色分け設定が計測値(ground)の場合のメモリ */}
      {nowAnalysisPointState.type === "ground" && 
        <div className={`${classes.legend__numbers}`}>
          <span className={`${classes.legend__number} ${classes.legend__number__max}`}>{selectedLegendMax}</span>
          <span className={`${classes.legend__number} ${classes.legend__number__zero}`}>0</span>
          <span className={`${classes.legend__number} ${classes.legend__number__min}`}>{selectedLegendMin}</span>
        </div>
      }

      {/* 解析点の色分け設定がランク(slope)の場合のメモリ */}
      {nowAnalysisPointState.type === "slope" && 
        <div className={`${classes.legend__numbers}`}>
          <span className={`${classes.legend__number } ${classes.legend__number__max__slope}`}>300</span>
          <span className={`${classes.legend__number} ${classes.legend__number__maxHarf__slope}`}>30</span>
          <span className={`${classes.legend__number} ${classes.legend__number__zero__slope}`}>10</span>
          <span className={`${classes.legend__number} ${classes.legend__number__minHarf__slope}`}>2</span>
          <span className={`${classes.legend__number} ${classes.legend__number__min__slope}`}>0</span>
        </div>
      }

      {/* 解析点の色分け設定が勾配の向きの場合のメモリ */}
      {nowAnalysisPointState.type === "gDirection" &&
        <div className={`${classes.legend__numbers}`}>
          <span className={`${classes.legend__number} ${classes.legend__direction}`} style={{top: '-5px'}}>北</span>
          <span className={`${classes.legend__number} ${classes.legend__direction}`} style={{top: '25px'}}>東</span>
          <span className={`${classes.legend__number} ${classes.legend__direction}`} style={{top: '55px'}}>南</span>
          <span className={`${classes.legend__number} ${classes.legend__direction}`} style={{bottom: '25px'}}>西</span>
          <span className={`${classes.legend__number} ${classes.legend__direction}`} style={{bottom: '-5px'}}>北</span>
        </div>
      }

      {/* 解析点の色分け設定が勾配の角度の場合のメモリ */}
      {nowAnalysisPointState.type === "gSizeDirection" && 
        <div className={`${classes.legend__numbers}`}>
          <span className={`${classes.legend__number} ${classes.legend__number__max__left}`}>60°</span>
          <span className={`${classes.legend__number} ${classes.legend__number__min0}`}>0°</span>
        </div>
      }

      {/* 解析点の色分け設定が信頼度の場合のメモリ */}
      {nowAnalysisPointState.type === "cReliability" && 
        <div className={`${classes.legend__numbers}`}>
          <span className={`${classes.legend__number} ${classes.legend__number__max__00}`}>1</span>
          <span className={`${classes.legend__number} ${classes.legend__number__min}`}>0.5</span>
        </div>
      }

      {/* カラーバー本体 */}
      {nowAnalysisPointState.type !== "none" && // 勾配(大きさの場合は非表示)
        <div className={`${classes.legend__corlor__bar} ${nowAnalysisPointState.type === "ground" ? classes.legend__corlor__ground : ''} ${nowAnalysisPointState.type === "slope" ? classes.legend__corlor__slope : ''} ${nowAnalysisPointState.type === "gDirection" ? classes.legend__corlor__gDirection : ''} ${nowAnalysisPointState.type === "gSizeDirection" ? classes.legend__corlor__gSizeDirection : ''} ${nowAnalysisPointState.type === "cReliability" ? classes.legend__corlor__cReliability : ''} `}></div>
      }
      </div>
  )
}
