import { useRef, useState, useCallback } from "react"
import { postRequestNoneKey } from 'utils/_function';
import axios from 'axios';

// マップ画面からラスター画像を格納したzipファイルをS3にアップロードする処理
export const useAddRasterImgData = (setMapLoading, projectId, accountGroupId) => {
  const [selectedRasterFile, setSelectedRasterFile] = useState(null); // アップロードするzipファイルのデータを格納
  const [uploadRasterName, setUploadRasterName] = useState(""); // アップロードするラスターデータ名を格納
  const [rasterNameInputModalIsOpen, setRasterNameInputModalIsOpen] = useState(false); // ラスター名入力モーダルの開閉を制御
  const fileInputRef = useRef(null);

  // ファイル選択UIを表示
  const handleClickUploadBtn = useCallback(() => fileInputRef.current.click(), [])

  // ラスターデータ(zip)ファイルを選択した際の処理
  const handleChangeSelectRasterFile = useCallback((e) => {
    setSelectedRasterFile(e.target.files[0]);
    setRasterNameInputModalIsOpen(true); // 名前入力モーダルを表示
  }, [])

  // ラスターデータ名入力モーダル非表示にする処理
  const handleClickRasterNameInputModalClose = useCallback(() => {
    setRasterNameInputModalIsOpen(false); // 名前入力モーダルを非表示
  })

  // ラスター名を入力した際のイベント処理
  const handleClickRasterNameInput = useCallback((e) => setUploadRasterName(e), [])

  // ラスター名決定時の処理
  const handleClickRasterDataPutS3 = useCallback(() => {
    if (uploadRasterName === "") return; // 入力値が空の場合は処理を停止
    setMapLoading(true); // ローディング開始
    setRasterNameInputModalIsOpen(false); // 名前入力モーダルを非表示

    // 署名付きURLを発行するAPIを実行しそのまま選択したファイルをS3にアップロード
    const fetchRasterDataPutS3 = async () => {
      const params = {
        projectId: String(projectId),
        AccountGroupId: String(accountGroupId),
        rasterName: String(uploadRasterName),
      }
      const response = await postRequestNoneKey('IM_Lambda', '/im_addrasterdata', params);
      switch (response.statusCode) {
        case 200: // 正常終了
          const result = await axios.put(response.body.signed_url, selectedRasterFile, {
            headers: {
              'Content-Type': selectedRasterFile.type
            }
          });
          // NOTE成功時の処理
          console.log(result);
          break;
        default:
          // NOTE失敗時の処理
          break;
      }
      setMapLoading(false); // ローディング終了
    }
    fetchRasterDataPutS3();

  }, [uploadRasterName, selectedRasterFile])


  return {
    fileInputRef,
    rasterNameInputModalIsOpen,
    handleChangeSelectRasterFile,
    handleClickRasterNameInputModalClose,
    handleClickRasterNameInput,
    handleClickRasterDataPutS3,
    handleClickUploadBtn
  }
}