import { useEffect, useState } from "react"
import { S3_URL } from "utils/constants";
import { postRequest } from 'utils/_function';

export const useFetchAoisGeojson = (projectId, accountGroupId, latitudeCenter, longitudeCenter, projectDates, projectAoiType, reFetchAoiData, setIsShowPurchaseAlertPopup, setMapLoading) => {

  const [aoisGeojsonFeatureData, setAoisGeojsonFeatureData] = useState({}); // APIで取得したaoi.geojsonのfeature部分のデータを格納
  const [staticAoisGeojson, setStaticAoisGeojson] = useState({}); // 静的ファイルgeojsonを格納
  const [reFetchAoiLoading, setReFetchAoiLoading] = useState(false); // geojson更新時のローディング状態を定義
  const [isFetchfirstAoigeojson, setIsFetchfirstAoigeojson] = useState(false); // 初回のgeojsonの取得完了フラグ
  const [analysisVer, setAnalysisVer] = useState(1.0); // 読み込むanalysis.geojsonのverを定義
  const [formatGeojson, setFormatGeojson] = useState({ // Map画面で使用するgeojsonの基本形を定義
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
          "name": "urn:ogc:def:crs:OGC:1.3:CRS84",
          "dates": projectDates,
          "pjLatCenter": Number(latitudeCenter),
          "pjLngCenter": Number(longitudeCenter)
      }
    },
    "features": {}
  })

  /* API経由でgeojsonを取得する処理 */
  const fetchAPIAoisGeojson = async (params) => {
    try{
      const response = await postRequest('IM_Lambda', '/im-getaoigeojson2', params);
      setAoisGeojsonFeatureData(response.result);
    }catch{
      setMapLoading(false); // ローディングオフ
      setReFetchAoiLoading(false); // aois.geojson更新時のローディングをオフ
      setIsShowPurchaseAlertPopup(true); // geojson取得に失敗した場合はモーダルを表示
    }
  }

  /* 静的ファイルgeojsonを取得する処理 */
  const fetchStaticAoisGeojson = async (aoiUrl) => {
    try{
      const aoisGeojson = await fetch(aoiUrl).then(response => response.json());
      if(aoisGeojson.crs.properties.version !== undefined){
        setAnalysisVer(Number(aoisGeojson.crs.properties.version) + 0.001); // analysis.geojsonのバージョンを保存
      }
      setStaticAoisGeojson(aoisGeojson);
    }catch{
      setMapLoading(false); // ローディングオフ
      setReFetchAoiLoading(false); // aois.geojson更新時のローディングをオフ
      setIsShowPurchaseAlertPopup(true); // geojson取得に失敗した場合はモーダルを表示
    }
  }

  /* AOItypeの取得が完了した時処理 */
  useEffect(() => {
    if(!projectAoiType) return;
    /**
    * projectAoiTypeがgridの場合は、API経由でgeojsonを取得する
    * projectAoiTypeがfree(grid以外)の場合は、静的ファイルgeojsonを参照する
    */
   if(projectAoiType === 'Grid'){
    //API経由で取得
    const params = {
      projectId: projectId,
      type: 2,
      AccountGroupId: accountGroupId,
      adjoin: 1
    }
    fetchAPIAoisGeojson(params);
   }else{
    //静的ファイルで取得
    const aoiUrl = `${S3_URL}geojson/${projectId}/aoi.geojson`;
    fetchStaticAoisGeojson(aoiUrl);
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAoiType])

  /* Gridフィルターモーダルの入力状態に基づいて再度geojsonを取得する */
  useEffect(() => {
    if(!reFetchAoiData) return;
    if(Object.keys(reFetchAoiData).length === 0) return; // 静的ファイルgeojson取得前は処理を停止
    setReFetchAoiLoading(true);

    // 購入済のみ表示が選択されている状態であればAPI経由でgeojsonを取得する
    if(reFetchAoiData.gridAoiShowType === "grid"){
      //API経由で取得
      const params = {
        projectId: projectId,
        type: 2,
        AccountGroupId: accountGroupId,
        adjoin: reFetchAoiData.adjoinNum
      }
      fetchAPIAoisGeojson(params);
    }else{
      //静的ファイルで取得
      const aoiUrl = `${S3_URL}geojson/${projectId}/aoi.geojson`;
      fetchStaticAoisGeojson(aoiUrl);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reFetchAoiData])

  /* API経由でgeojsonを取得した際の処理 */
  useEffect(() => {
    if(Object.keys(aoisGeojsonFeatureData).length === 0) return; // features部分が取得されていない場合は処理を停止

    //formatGeojsonの更新
    setFormatGeojson(prevState => ({
      ...prevState,
      features: aoisGeojsonFeatureData
    }));
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoisGeojsonFeatureData])

  /* 静的ファイルgeojsonを取得した際の処理 */
  useEffect(() => {
    if(Object.keys(staticAoisGeojson).length === 0) return; // 静的ファイルgeojson取得前は処理を停止

    //formatGeojsonの更新
    setFormatGeojson(staticAoisGeojson);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[staticAoisGeojson])

  /* ダッシュボードの斜面変動ランク一覧で使用するgeojsonを取得(購入しているAOIのみ) */
  const fetchDashBoardAoiGeojson = async (selectedRefProjectId, accountGroupId) => {
    const params = {
      projectId: selectedRefProjectId,
      type: 2,
      AccountGroupId: accountGroupId,
      adjoin: 0
    }
    try{
      const response = await postRequest('IM_Lambda', '/im-getaoigeojson2', params);
      return response.result;
    }catch{
      return 'error';
    }
  }

  return{
    formatGeojson,
    isFetchfirstAoigeojson,
    reFetchAoiLoading,
    analysisVer,
    fetchDashBoardAoiGeojson,
    setIsFetchfirstAoigeojson,
    setReFetchAoiLoading
  }
}