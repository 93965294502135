import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({ formControl: { minWidth: 200 } }));

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

// 解析点のフィルタリングを行うモーダル
const AnalysisPointFilteringModal = (props) => {
  const {
    analysisFilteringModalIsOpen,
    handleChangeAnalysisFilteringModalClose,
    filteringSubjects,
    filtergingConditions,
    analysisFilteringArrays,
    handleClickAddfilteringRecord,
    addFilteringFlag,
    handleClickRemovefilteringRecord,
    handleChangeFilteringTextSelectInput,
    handleClickAnalysisPonitFilteringFlag,
    handleClickAnalysisPonitFilteringResetFlag
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      open={analysisFilteringModalIsOpen}
      PaperComponent={PaperComponent}
      onClose={() => handleChangeAnalysisFilteringModalClose()}
    >
      <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center", cursor: 'move'}} id="draggable-dialog-title">
        <Box style={{ fontSize: "16px", padding: "15px" }}>点のフィルタリング</Box>
        <Box pr={1.8}><Button onClick={handleChangeAnalysisFilteringModalClose}><HighlightOffIcon/></Button></Box>
      </Box>
      <Box style={{ padding: "0 15px 15px 15px" }}>
        {analysisFilteringArrays.map((analysisFilteringArray, index) => (
          <Box
            key={index}
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <Checkbox
              checked={analysisFilteringArray.active}
              color="primary"
              onChange={(e) => handleChangeFilteringTextSelectInput(e.target.checked, "active", index)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <FormControl style={{ marginRight: "15px" }} variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="demo-simple-select-outlined-label">対象</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={analysisFilteringArray.subject}
                onChange={(e) => handleChangeFilteringTextSelectInput(e.target.value, "subject", index)}
                label="対象"
              >
                {filteringSubjects.map(filteringSubject => (
                  <MenuItem key={filteringSubject.key} value={filteringSubject.value}>{filteringSubject.key}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginRight: "15px" }} variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="demo-simple-select-outlined-label">条件</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={analysisFilteringArray.conditions}
                onChange={(e) => handleChangeFilteringTextSelectInput(e.target.value, "conditions", index)}
                label="条件"
              >
                {filtergingConditions.map(filtergingCondition => (
                  <MenuItem key={filtergingCondition.operator} value={filtergingCondition.operator}>{filtergingCondition.operator}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField style={{ marginRight: "15px" }} type="number" label="値" variant="outlined" size="small" value={analysisFilteringArray.value} onChange={(e) => handleChangeFilteringTextSelectInput(e.target.value, "value", index)} />
            <Button variant="contained" color="default" size="small" onClick={() => handleClickRemovefilteringRecord(index)}><RemoveCircleOutlineIcon/></Button>
          </Box>
        ))}
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" color="primary" size="medium" disabled={addFilteringFlag} onClick={handleClickAddfilteringRecord}><ControlPointIcon/></Button>
          <Box>
            <Button variant="contained" color="primary" size="medium" style={{ minWidth: "100px", marginRight: "10px" }} disabled={addFilteringFlag} onClick={handleClickAnalysisPonitFilteringFlag}>反映</Button>
            <Button variant="contained" color="default" size="medium" style={{ minWidth: "100px" }} disabled={addFilteringFlag} onClick={handleClickAnalysisPonitFilteringResetFlag}>リセット</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default React.memo(AnalysisPointFilteringModal);