import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

// ラスター名入力モーダル
const UploadRasterNameModal = (props) => {
  const {
    rasterNameInputModalIsOpen,
    handleClickRasterNameInputModalClose,
    handleClickRasterNameInput,
    handleClickRasterDataPutS3
  } = props;

  return (
    <Dialog
      maxWidth="lg"
      open={rasterNameInputModalIsOpen}
      onClose={() => handleClickRasterNameInputModalClose()}
    >
      <Box p={3}>
        <Box mb={2}>独自ラスター名を入力してください</Box>
        <Box style={{ display: "flex" }}>
          <TextField id="outlined-basic" label="ラスター名" variant="outlined" style={{minWidth: "350px"}} onChange={(e) => handleClickRasterNameInput(e.target.value)}/>
          <Button style={{ marginLeft: "15px" }} variant="contained" color="primary" onClick={handleClickRasterDataPutS3}><ControlPointIcon /></Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default React.memo(UploadRasterNameModal);